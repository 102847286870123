.referral {
  &-wrapper {
    background: $white;
    border-radius: 20px;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1) !important;
    padding: 24px;
  }

  &-percent {
    border: 1px #000000 solid;
    border-radius: 100px;
    padding: {
      top: 10px;
      bottom: 10px;
      left: 20px;
      right: 20px;
    }
  }

  &-block {
    background: $white;
    border-radius: 100px;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1) !important;
    padding: 15px;
    word-break: break-all;

    & > .p-button {
      padding: 0.5rem 1rem;
    }
  }

  &-form {
    max-width: 500px;
    width: 100%;

    &-button {
      width: 140px;
      height: 60px;
    }
  }
}

@media screen and (max-width: 640px) {
  .referral {
    &-block {
      border-radius: 40px;
    }
  }
}
