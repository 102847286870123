// colors
$main: #89a51e;
$bacground: #f5f5f5;
$text: #524f52;
$icon: #221f23;
$disable: #e5e5eb;
$success: #35b37e;
$error: #e40000;
$warning: #f4970c;
$white: #ffffff;
$adding: #1677ff;
$white: #ffffff;
$gray: #bcbfbe;
$hover: #f4fade;
$deleted: #fadede;
$completed: #eaf1d0;