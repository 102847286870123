.offers {
  margin: 40px;
  &--title {
    font-size: 32px;
    line-height: 40px;
    font-family: "Medium";
  }

  &--filters {
    margin-top: 44px;
    &-filter {
      margin-right: 16px;
      .p-dropdown {
        margin-top: 8px;
        // height: 56px !important;
        padding: 21px 16px 21px 16px;
        border: none;
        border-radius: 10px;
        width: 256px !important;
        box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);

        .p-dropdown-label {
          padding: 0;
        }
      }
    }
  }

  &--card {
    background-color: $white;
    padding: 24px;
    border-radius: 20px;
    margin-top: 32px;

    &_title {
      font-size: 24px;
      font-family: "Medium";
    }

    &_fields {
      margin-top:28px;
      &-item {
        &_label {
          font-weight: 600 !important;
        }

        button {
          @include standart-button(106px, 40px);
          cursor: pointer;
          font-size: 14px;
          font-family: "Regular";
        }
      }
    }
  }

  &--dialog {
    width: 98% !important;
    height: 98% !important;
  }

  &--category {
    &--self {
      .offers--category_details {
        margin-top: 0 !important;
      }
    }
    background-color: $white;
    border-radius: 20px;

    &_title {
      margin-left: 19px;
      font-size: 24px;
      font-family: "Medium";
      font-weight: 500;
      padding-top: 40px;
    }

    &_list {
      margin-left: 19px;
      margin-top: 32px;

      &_item {
        &-fill {
          color: $gray !important;
          border: 1px solid $gray !important;
        }
      }
      ul {
        padding: 0;
        li:not(.active) {
          @include standart-button();
          padding: 0 40px 0 40px;
          display: inline-block;
          margin-right: 16px;
          margin-bottom: 16px;
          height: 56px;
          line-height: 56px;
          color: $text;
          width: auto;
          background-color: $white;
          border: 1px solid $main;
          cursor: pointer;
        }

        li {
          @include standart-button();
          padding: 0 40px 0 40px;
          display: inline-block;
          margin-right: 16px;
          margin-bottom: 16px;
          height: 56px;
          line-height: 56px;
          width: auto;
          cursor: pointer;
        }
      }
    }

    &_table {
      margin-top: 17px !important;

      .p-accordion .p-accordion-header .p-accordion-header-link {
        padding: 1rem;
      }

      .p-datatable .p-datatable-thead > tr > th, td {
        padding: 1rem 1rem;
      }
    }

    &_details {
      margin-top: 80px;
      &_title {
        span {  
          margin-left: 37px;
          font-size: 24px;
          font-family: "Medium";
        }
      }

      .p-accordion {
        box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        margin: 40px 33px 40px 33px;
        width: 100%;

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input[type=number] {
          -moz-appearance: textfield;
        }

        input[type=number] {
          width: 100%;
          margin: 8px 0;
          box-sizing: border-box;
          border: none;
          border-bottom: 1px solid $text;
        }

        .p-accordion-header-link {
          background-color: $white;
          border: none;
        }

        .p-accordion-content {
          border: none;
        }
      }
    }

    &_sendbutton {
      button {
        @include standart-button(162px, 56px);
        cursor: pointer;
        font-size: 16px;
        font-family: "Regular";
        margin-right: 32px;
        margin-bottom: 32px;
      }
    }
  }

  &-post {
    & > .offers--category_title {
      padding-top: 40px;
    }
  }
}