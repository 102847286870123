.not-found {
  margin-top: 10%;
  text-align: center;

  &--title {
    font-size: 32px;
    font-family: "SemiBold";
  }

  &--img {
    margin-top: 27px;
  }

  &--description {
    margin-top: 40px;
    font-size: 16px;
    font-family: "Regular";

    div {
      max-width: 428px;
    }
  }

  &--btn {
    margin-top: 40px;
    button {
      @include standart-button(314px, 56px);
      cursor: pointer;
      font-size: 14px;
      font-family: "Regular";
    }
  }
}