.toogler {
    cursor: pointer;
    width: 66px;
    height: 28px;
    background: $white;
    position: relative;
    transition: background-color 0.2s;
    border: 1px solid $gray;
    border-radius: 30px;
  
    &-checkbox {
      height: 0;
      width: 0;
      position: absolute;
      visibility: hidden;
    }
  
    &-checkbox:checked + &-button {
      left: calc(100%);
      transform: translateX(-100%);
    }
  
    &-checkbox:checked ~ &-labels {
      span:last-child {
        display: none;
      }
    }
  
    &-checkbox:not(:checked) ~ &-labels {
      span:first-child {
        display: none;
      }
    }
  
    &-button {
      cursor: pointer;
      position: absolute;
      z-index: 1;
      left: 0.1px;
      width: 26px;
      height: 26px;
      transition: 0.2s;
      border-radius: 30px;
      background-color: $main;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
    }
  
    &-labels {
      display: relative;
      z-index: 0;
      height: 100%;
  
      span {
        font-size: 12px;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 100%;
      }
  
      span:first-child {
        margin-left: 5px;
        left: 0;
      }
  
      span:last-child {
        margin-right: 5px;
        right: 0;
      }
    }
  }