* {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0;

  .font-custom-green {
    color: $main;
  }
}

.input {
  &--new {
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: none;
  padding: 0;

    & .p-dropdown-trigger {
      &[aria-expanded="true"] {
        transform: rotate(180deg);
      }
    }
  }
}

.select {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  border-radius:10px;
  

  &__header {
    padding: 21px 16px;
    border: none;
    border-radius: 10px;
    color: #353535;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &--active {
      border-radius: 10px 10px 0 0;

      .select__icon {
        transform: rotate(0deg);
      }
    }
  }

  &__body {
    display: none;

    &--visible {
      display: block;
    }
  }

  &__icon {
    width: 10px;
    height: 6px;
    position: relative;
    transform: rotate(180deg);

    & svg {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  &__item {
    padding: 20px 16px;
    border-top: 1px solid #BCBFBE;
    cursor: pointer;

    &:hover {
      background-color: #F4FADE;
    }

    &:last-child {
      border-radius: 0 0 10px 10px;
    }
  }
}

.swiper {
  &:hover {
    & > .swiper-horizontal > .swiper-scrollbar, 
    .swiper-scrollbar.swiper-scrollbar-horizontal {
      opacity: 1 !important;
    }
  }

  & .swiper-wrapper {
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
    -webkit-animation-name: swiper-hover;
            animation-name: swiper-hover;
  }
}

.checkbox-select-item {
  display: flex;
  align-items: center;
  gap: 14px;

  &__icon {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid #BCBFBE;

    &--active {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #89A51E;

      & svg {
        & > path {
          fill: $white
        }
      }
    }
  }
}

@-webkit-keyframes swiper-hover {
  0% {
    transform: translate3d(0px, 0px, 0px);
  }
  50% {
    transform: translate3d(-50px, 0px, 0px);
  }
  100% {
    transform: translate3d(0px, 0px, 0px);
  }
}

@keyframes swiper-hover {
  0% {
    transform: translate3d(0px, 0px, 0px);
  }
  50% {
    transform: translate3d(-50px, 0px, 0px);
  }
  100% {
    transform: translate3d(0px, 0px, 0px);
  }
}


// костыль дропдауна, именить на ручной

.p-dropdown-panel {
  .p-dropdown-items {
    padding: 0.5rem 0;
    .p-dropdown-item {
      padding: 0.5rem 1rem;
    }
  }
}

.p-dialog {
  & .p-dialog-content {
      padding: 0 1.5rem 2rem 1.5rem;
    }
  & .p-dialog-header {
    padding: 1.5rem;
  }
}
