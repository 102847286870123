.main-header {
  border-radius: 0px 0px 20px 20px;
  background-color: $white;
  z-index: 100;

  &--mobile-button {
    display: none;
  }

  &--wrapper {
    padding: 9px 40px 22px 40px;
  }

  &-level-top {
    gap: 10px;
    padding-bottom: 7px;
    border-bottom: 1px solid $gray;

    &--links {
      cursor: pointer;
      padding: 0;
      font-size: 13px;
      line-height: 13px;
      font-family: "Regular";
      font-weight: 400;
      color: $text;
      a {
        text-decoration: none;
        color: $text;
      }
      li {
        margin-right: 16px;

        & > a {
          & > span {
            font-family: 'Regular';
            font-weight: 400 !important;
          }
        }
      }
    }
  }

  li {
    display: inline;
  }

  &-level-user {
    height: 30px;
  }

  &--toggler {
    margin-right: 11px;
  }

  &-icon {
    width: 30px !important;
    height: 30px !important;
    margin: 0 5px;
    cursor: pointer;

    &--mappicker {
      margin-right: 8px;
      width: 10px !important;
      height: 14px !important;
    }

    &--logo {
      margin-right: 2px;
      width: 264px !important;
      height: 24px !important;
    }
  }

  &-level-bottom {
    padding-top: 28px;
    
    &--links {
      font-family: 'Medium';
      font-weight: 500;
      font-size: 16px;
      line-height: 13px;
      color: $text;

      &_cart {
        position: relative;

        &_count {
          position: absolute;
          right: 0;
          width: 16px;
          height: 16px;
          font-size: 10px;
          background-color: $main;
          border-radius: 100%;
          color: $white;
        }
      }

      a {
        text-decoration: none;
        color: $text;
        font-size: 16px;
        line-height: 13px;
      }

      li {
        margin-right: 25px;
        cursor: pointer;
      }

      li:last-child {
        margin-right: 0;
      }

      &_additional {
        background-color: $main;
        border-radius: 5px;
        padding: 2px 4px 4px 4px;
        width: 38px;
        height: 20px;
        font-size: 10px;
        color: $white;
        margin-left: 12px;
      }
    }

    &--user {
      display: none !important;
    }
  }

  &--button {
    @include standart-button(128px, 40px);
    padding: 10px 40px 10px 40px;
    font-size: 12px;
    font-family: "Medium";
  }
}

@media screen and (max-width: $mdBreakpoint) {
  .main-header {
    max-height: 104px;

    &--mobile-button {
      display: block !important;

      a {
        text-decoration: none;
        color: $text;
        font-size: 16px;
        line-height: 13px;
      }

      button {
        @include standart-button(73px, 32px);
        padding: 8px 16px 8px 16px;
        font-size: 12px;
        font-family: "Regular";
      }
    }

    &--wrapper {
      margin: 0 16px 0 16px;
    }

    &-level {
      &-bottom {
        &--wrap {
          display: none !important;
        }
        margin-top: 30px;
        &--info {
          width: 100%;
        }
      }
      // &-top {
      //   border-bottom: none;
      //   &--links {
      //     li:not(&--city) {
      //       display: none;  
      //     }
      //   }
      // }

      &-bottom {
        width: 100%;
        &--wrap {
          margin-bottom: 30px;
        }

        &--links {
          display: none !important;
        }

        &--wrap {
          width: 100%;

          + div {
            width: 100%;
          }
        }
        .main-header-icon--logo {
          height: 12px !important;
          width: 134px !important;
        }
        &--user {
          display: block !important;

          li {
            display: inline-block;
            margin-left: 8px;
            svg {
              margin: 0;
            }
          }
        }
      }
    }
  }
}