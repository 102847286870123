.main-footer {
    margin-top: 80px;
    bottom: 0;
    border-radius: 20px 20px 0px 0px;
    background-color: $white;
    padding: 60px 40px 32px 40px;
    bottom: 0;
    width: 100%;
    box-shadow: 0px -4px 6px 0px rgba(0, 0, 0, 0.1);

    .help-button {
      text-decoration: none;
    }

    .tab-bar {
      display: none;
    }

    .mobile {
      display: none !important;
    }

    .desktop {
      padding-top: 40px;
    }
  
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
  
    &--social-network-list {
      margin-top: 26px !important;
      padding: 0;
      li {
        cursor: pointer;
        display: inline;
        margin-right: 9.88px;
  
        svg {
          height: 20px !important;
        }
      }
  
      &_disclaimer {
        top: -10px;
        position: relative;
        left: -10px;
      }
    }
  
    &--social-info-list, &--social-calc-list {
      padding: 0;
  
      li:first-child {
        font-family: "SemiBold";
        font-size: 16px;
        cursor: auto;
      }
  
      li {
        cursor: pointer;
        margin-bottom: 11px;
        font-size: 13px;
      }

      a {
        text-decoration: none;
        color: $text;
      }
    }
  
    &--address-list {
      li:not(.main-footer--address-list_email) {
        font-size: 13px;
        line-height: 13px;
        letter-spacing: 0.5px;
      }
  
      &_email {
        margin-top: 6px;
        font-size: 14px;
        line-height: 16px;
        font-family: "Regular";
        text-decoration: underline;
      }
    }
  
    &--disclaimer {
      font-size: 10px !important;
    }
  
    &--tos {
      font-size: 13px;
      text-align: right;
  
      span {
        cursor: pointer;
        color: $main;
        text-decoration: underline;
      }
    }
  
    &--disclaimer, &--tos {
      line-height: 16px;
      font-family: "Light";
    }
  
    &--bottom {
      padding-top: 60px;
    }
  
    button {
      @include standart-button(108px, 40px);
      padding: 10px 24px 10px 24px;
      margin-top: 17px;
      font-family: 'Regular';
      font-weight: 400;
      font-size: 14px;
      line-height: 20;
    }
  }

@media screen and (max-width: $mdBreakpoint) {

  .main-footer { 
    padding: 24px 16px 0 16px;
    height: auto;

    .desktop {
      display: none !important;
    }

    &--bottom {
      padding-top: 0;
    }

    &--tos {
      margin-top: 50px;
      margin-bottom: 25px;
      font-size: 10px;
      text-align: start;
    }

    .mobile {
      display: block !important;

      .logo {
        margin-top: 50px
      }

      .main-footer {
        &--button {
          @include standart-button(81px, 32px);
          font-size: 12px;
          line-height: 400;
        }

        &--address-list {
          li {
            display: block !important;
            font-size: 14px;
            line-height: 20px;
            font-family: "Medium";
            margin-bottom: 4px;
          }
        }
      }
    }
  }
}