.documents {
  padding: 32px 40px;

  &__swiper {
    width: 100%;
    max-width: 1840px;
    height: 150px;
    margin: 52px auto 40px;
    overflow: hidden;

    @media screen and (max-width: 1919px) {
      max-width: 1600px;
    }

    @media screen and (max-width: 1678px) {
      width: 1200px;
      height: 172px;
    }

    @media screen and (max-width: 1279px) {
      width: 930px;
    }

    @media screen and (max-width: 1049px) {
      max-width: 700px;
    }

    @media screen and (max-width: 774px) {
      width: 450px;
    }

    @media screen and (max-width: 529px) {
      width: 100%;
    }
  }

  &__list {
    margin: 25px auto 0;
    width: 100%;
    max-width: 1840px;

    @media screen and (max-width: 1919px) {
      max-width: 1600px;
    }

    @media screen and (max-width: 1678px) {
      max-width: 1200px;
    }

    @media screen and (max-width: 1279px) {
      max-width: 930px;
    }

    @media screen and (max-width: 1049px) {
      max-width: 700px;
    }
  }

  &__modal {
    border-radius: 20px;
    width: 95%;
    max-width: 1740px;

    & > .p-dialog-header {
      border-radius: 20px 20px 0 0;
      padding: 32px 48px 24px;
    }

    & > .p-dialog-content {
      border-radius: 0 0 20px 20px;
      padding: 0 48px 32px;
    }
  }

  @media screen and (max-width: 529px) {
    padding: 25px 16px;
  }
}

.documents-modal {
  &__download {
    font-family: 'Regular';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-decoration: underline;
    cursor: pointer;
  }

  &__preview {
    & > .pg-viewer-wrapper {
      & .document-container {
        padding-bottom: 0;
      }
    }
  }

  &__button {
    width: 300px;
    margin-left: auto;
    margin-top: 24px;
    border: 1px solid $main;
    text-align: center;
    padding: 10px 20px;
    border-radius: 12px;
    cursor: pointer;

    &--disable {
      border-color: $gray;
    }

    @media screen and (max-width: 744px) {
      width: 100%;
      margin: 24px auto 0;
    }
  }
}

.documents-header {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1840px;
    margin: 0 auto;

    @media screen and (max-width: 1919px) {
      max-width: 1600px;
    }

    @media screen and (max-width: 1678px) {
      max-width: 1200px;
    }

    @media screen and (max-width: 1279px) {
      max-width: 930px;
    }

    @media screen and (max-width: 1049px) {
      max-width: 700px;
    }
  }
  

  &__title {
    font-family: "Medium";
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 8px;
    border: 1px solid $icon;
    border-radius: 10px;
    padding: 8px;

    @media screen and (max-width: 1049px) {
      border: none;
    }
  }
}

.documents-header-info {
  @media screen and (max-width: 1049px) {
    position: relative;
    z-index: 4;
  }

  &__icon {
    height: 24px;
    order: 1;

    @media screen and (max-width: 1049px) {
      position: relative;
      z-index: 3;

      &:hover {
        + .documents-header-info__text {
          position: absolute;
          top: 0;
          right: 0;
          width: 255px;
          display: block;
          border: 1px solid $icon;
          border-radius: 10px;
          padding: 8px;
          background-color: $white;
          z-index: 2;
        }
      }
    }
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
    color: $icon;

    @media screen and (max-width: 1049px) {
      display: none;
    }
  }
}

.documents-swiper {
  &__slide {
    background-color: $white;
    border-radius: 20px;
    padding: 8px 16px;

    position: relative;
    cursor: pointer;
  }
}

.documents-swiper-slide {
  &__text {
    color: $main;
  }

  &__img {
    position: absolute;
    right: 0;
    bottom: 0;

    &--active {
      path {
        stroke: $main;
      }
    }
  }
}

.documents-filters {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1840px;
    margin: 0 auto;

    @media screen and (max-width: 1919px) {
      max-width: 1600px;
    }

    @media screen and (max-width: 1678px) {
      max-width: 1200px;
    }

    @media screen and (max-width: 1279px) {
      max-width: 930px;
    }

    @media screen and (max-width: 1049px) {
      max-width: 700px;
    }

    @media screen and (max-width: 630px) {
      width: 100%;
      max-width: 480px;
      margin: 0 auto;
      justify-content: center;
    }
  }
}

.documents-filters-status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  &__button {
    width: 132px;
    padding: 10px 20px;
    border-radius: 100px;
    border: 1px solid $main;
    background-color: $white;
    color: $text;
    font-size: 14px;
    line-height: 24px;
    text-align: center;

    &--active {
      background-color: $main;
      color: $white;
    }
  }
}

.documents-filters-added {
  &__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 10px 12px;
    background-color: $white;
    border: 1px solid $main;
    border-radius: 100px;
    margin-left: auto;
    cursor: pointer;
    color: $text;
    font-size: 14px;
    line-height: 24px;

    @media screen and (max-width: 630px) {
      width: 280px;
      justify-content: center;
      margin-left: 0;
    }
  }

  &__icon {
    height: 24px;

    path {
      fill: $icon;
    }
  }
}

.documents-filters-filter {
  padding: 32px 0 40px;

  @media screen and (max-width: 630px) {
    padding-top: 16px;
  }

  &__wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    flex: 1 1 250px;
    margin: 0 auto;
    width: 100%;
    max-width: 1840px;
    gap: 8px;

    @media screen and (max-width: 1919px) {
      max-width: 1600px;
    }

    @media screen and (max-width: 1678px) {
      max-width: 1200px;
    }

    @media screen and (max-width: 1279px) {
      max-width: 950px;
      justify-content: center;
    }

    @media screen and (max-width: 1049px) {
      max-width: 700px;
    }
  }

  &__search {
    width: 100%;
    max-width: 280px;
  }
}

.documents-filters-filter-search {
  @media screen and (min-width: 631px) {
    display: none;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 10px 12px;
  background-color: $white;
  border: 1px solid #89a51e;
  border-radius: 100px;
  cursor: pointer;
  color: $text;

  &__text {
    font-size: 14px;
    line-height: 24px;
  }

  &__icon {
    width: 18px;
    height: 18px;
  }
}

.documents-filters-filter-item {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (max-width: 630px) {
    display: none;
  }


  &__name {
    font-size: 16px;
    line-height: 24px;
    color: #000;
  }

  &__dropdown {
    padding: 18px 13px;
    width: 100%;
    min-width: 330px;
    max-width: 500px;

    @media screen and (max-width: 1678px) {
      width: 270px;
      min-width: 0;
    }

    @media screen and (max-width: 1279px) {
      width: 270px;
    }

    & * {
      padding: 0;
    }
  }

  &__form {
    position: relative;
  }

  &__search {
    padding: 18px 37px 18px 13px;
    width: 496px;
    line-height: 21px;
    position: relative;

    @media screen and (max-width: 1678px) {
      width: 305px;
    }

    @media screen and (max-width: 1279px) {
      width: 394px;
    }

    & * {
      padding: 0;
    }
  }

  &__icon {
    position: absolute;
    z-index: 1;
    width: 18px;
    height: 18px;
    top: 19px;
    right: 16px;

    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.documents-filters-filter-item-dropdown {
  &__item {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.documents-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 48px;

  @media screen and (max-width: 1919px) {
    gap: 20px 21px;
  }

  @media screen and (max-width: 1279px) {
    justify-content: space-between;
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 13px;
    padding: 16px;
    width: 100%;
    max-width: 424px;
    border-radius: 20px;
    background-color: $white;
    cursor: pointer;

    @media screen and (max-width: 1919px) {
      width: 384px;
    }

    @media screen and (max-width: 1280px) {
      width: 384px;
    }

    @media screen and (max-width: 1049px) {
      width: 339px;
    }

    @media screen and (max-width: 778px) {
      width: 100%;
      max-width: none;
    }

    &--completed {
      padding: 8px 16px;
    }
  }
}

.documents-list-link {
  text-decoration: none;
  position: relative;

  &__img {
    width: 28px;
    height: 35px;
    
    svg {
      width: 100%;
      height: auto;

      path {
        fill: rgba(0, 0, 0, 0.45);
      }
     }
  }

  &__content {
    width: 100%;
  }

  &__close {
    position: absolute;
    right: 21px;
    top: 21px;
    display: none;    
  }

  &:hover {
    .documents-list-link {
      &__close {
        display: block;
        cursor: pointer;
      }
    }
  }
}

.documents-link-content {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__name {
    display: block;
    width: 230px;
    margin-bottom: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Medium';
    font-size: 16px;
    line-height: 24px;
    color: $text;

    @media screen and (max-width: 340px) {
      width: 200px;
    }
  }

  &__number {
    display: block;
    font-family: 'Regular';
    font-size: 12px;
    line-height: 12px;
    color: $gray;

    @media screen and (max-width: 420px) {
      margin-bottom: 8px;
    }
  }

  @media screen and (max-width: 420px) {
    flex-direction: column-reverse;
    align-items: start;
  }
}

.documents-link-content-status {
  display: flex;
  align-items: center;
  gap: 4px;

  &__img {
    width: 16px;
    height: 16px;

    & > svg {
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    font-family: 'Regular';
    font-size: 12px;
    line-height: 12px;
    color: $text;
  }
}

.documents-filter-modal {
  width: 100%;
  max-width: 489px;
  height: auto;
  border-radius: 20px;

  &__wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 100%;
  }

  &__button {
    padding: 17px 40px;
    border: 1px solid $main;
    border-radius: 100px;
    background-color: $main;
    color: $white;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
  }

  & .p-dialog-title {
    font-size: 20px;
    line-height: 24px;
    color: #000;
  }

  & .p-dialog-header-close {
    path{
      fill: $icon;
    }
  }

  & .p-dialog-header {
    border-radius: 20px 20px 0 0;
    background-color: $bacground;
  }

  & .p-dialog-content {
    border-radius: 0 0 20px 20px;
    background-color: $bacground;
  }
}

.documents-filter-modal-input {
  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 14px;
    gap: 12px;
    position: relative;
  }

  &__lable {
    font-size: 16px;
    line-height: 24px;
    color: #000;
  }

  &__item {
    color: $text;

    &--dropdown {
      padding: 18px 13px;
      font-size: 14px;
      line-height: 20px;

      & * {
        padding: 0;
      }
    }
  }

  &__search {
    font-size: 14px;
    line-height: 20px;
    color: $text;
    padding: 18px 13px;
    width: 100%;
    margin-bottom: 40px;
  }

  &__icon {
    position: absolute;
    z-index: 1;
    width: 18px;
    height: 18px;
    top: 19px;
    right: 16px;

    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.documents-added-modal {
  width: 100%;
  max-width: 489px;
  height: auto;
  border-radius: 20px;

  &__wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 100%;
  }

  &__title {
    text-align: center;
    font-size: 24px;
    line-height: 39px;
    margin: 0 0 42px;
    padding: 0;
  }

  & .p-dialog-header {
    display: none;
  }

  & .p-dialog-content {
    border-radius: 20px;
    padding: 20px;
  }
}

.documents-added-modal-form {
  &__lable {
    display: block;
    width: 100%;
    position: relative;
    margin-bottom: 8px;
  }

  &__text {
    position: relative;
    display: block;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    font-size: 16px;
    line-height: 24px;
    color: $main;
    text-align: center;
    border-radius: 12px;
    background-color: $white;
    width: 100%;
    padding: 24px;
    border: 2px dashed $main;
    margin: 0;
  }

  &__explanation {
    color: $gray;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 32px;
  }

  &__input {


    &--file {
      position: absolute;
      z-index: -1;
      opacity: 0;
      display: block;
      width: 0;
      height: 0;
    }
  }
}

.documents-added-modal-input {
  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 32px;
    gap: 12px;
  }

  &__lable {
    font-size: 16px;
    line-height: 24px;
    color: $text;
  }

  &__item {
    padding: 21px 16px;
    font-size: 16px;
    line-height: 24px;
    color: $text;

    &--dropdown {
      padding: 18px 13px;
      font-size: 14px;
      line-height: 20px;

      & * {
        padding: 0;
      }
    }
  }
}

.documents-added-modal-form-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &__item {
    padding: 17px 40px;
    border: 1px solid $main;
    border-radius: 100px;
    background-color: $white;
    color: $text;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;

    @media screen and (max-width: 425px) {
      padding: 17px 20px;
    }

    &--active {
      background-color: $main;
      color: $white;
    }
  }
}