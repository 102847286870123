.auth {
  &-form {
    margin-top: 180px;
    margin-bottom: 150px;
    background-color: $white;
    max-width: 489px;
    padding: 32px 48px 32px 48px;
    border-radius: 20px;

    &-title {
      font-size: 24px;
      line-height: 20px;
    }

    &-description {
      font-size: 16px;
      line-height: 25px;
      margin-top: 40px;
      margin-bottom: 12px;
    }

    &-label {
      color: $text;
      font-size: 16px;
      margin-top: 20px;
    }

    &-input {
      width: 100%;
      height: 66px;
      border: none;
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1) !important;
      border-radius: 10px;
      padding: {
        left: 10px;
        right: 40px;
      }

      &-error {
        border: solid $warning !important;
      }

      &::-webkit-input-placeholder {
        color: #bcbfbe;
        font-size: 14px;
      }

      &::-moz-placeholder {
        color: #bcbfbe;
        font-size: 14px;
      }

      &:-ms-input-placeholder {
        color: #bcbfbe;
        font-size: 14px;
      }

      &::-ms-input-placeholder {
        color: #bcbfbe;
        font-size: 14px;
      }

      &::placeholder {
        color: $gray;
        font-size: 14px;
      }

      &:disabled {
        background: $white;
      }
    }

    &-registration-button {
      margin-top: 30px;
      padding: 16px 40px 16px 40px;
      @include standart-button(241px, 56px);
    }

    &-login-button, {
      @include standart-button(127px, 56px);
      margin-top: 30px;
      padding: 16px 40px 16px 40px;
    }

    &-select-button {
      background: inherit;
      color: $text;
      width: 155px;
      border: 1px solid $main !important;
    }

    &-control-buttons {
      margin-top: 32px;

      &--prev,
      &--next {
        min-width: 125px;
      }

      &--next {
        background-color: $main;
        border: none !important;
      }

      &--prev {
        background: inherit;
        color: $disable;
        border: 1px solid $disable;
      }
    }

    &-forget {
      margin-top: 16px;
      font-size: 10px;
      font-family: "Regular";
      cursor: pointer;
    }

    button {
      padding: 16px 40px 16px 40px;
      min-height: 56px;
      border-radius: 100px;
      width: 100%;
    }

    &--registration-button {
      margin-top: 16px;
      text-decoration: underline;
      cursor: pointer;
    }

    &-tos {
      input:checked {
        // background-color: red;
      }

      label {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .auth-form-label {
      .input-container {
        margin-top: 12px;
        padding: 0;
        letter-spacing: 0;

        svg {
          right: 16px;
        }
      }
    }

    .input-container {
      input {
        font-size: 14px;
      }
      .input-error-info {
        border: 2px solid $warning;
      }
      input:focus{
        outline: none;
      }

      .input-password {
        letter-spacing: 0;
        padding-right: 80px;

        &-icon {
          right: 16px;
        }
      }
    }

    &-restore-button {
      @include standart-button(129px, 56px);
      margin-top: 30px;
      padding: 16x 40px 16px 40px;
    }

    &--restore {
      max-width: 500px !important;
      padding-left: 21px !important;
      padding-right: 21px !important;

      .auth-form-label {
        margin-left: 36px;
        margin-right: 36px;
      }

      &-pending {
        text-align: center;
      }
      &-info {
        margin-top: 40px;
        font-size: 24px;
        font-family: "Medium";
      }

      &-description,
      &-timer {
        margin-top: 32px;

        & > a {
          & > button {
            background-color: $main;
            color: $white;
            border: none;
          }
        }
      }

      &-timer {
        &--link {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }

  // input[type=checkbox] {
  //   position: relative;
  //   cursor: pointer;
  //   margin-right: 10px;
  // }
  // input[type=checkbox]:before {
  //     content: '';
  //     display: block;
  //     position: absolute;
  //     width: 10px;
  //     height: 10px;
  //     top: 0;
  //     left: 0;
  //     border: 2px solid #555555;
  //     border-radius: 3px;
  //     background-color: white;
  // }
  // input[type=checkbox]:checked:after {
  //     content: "";
  //     display: block;
  //     width: 3px;
  //     height: 6px;
  //     border: solid black;
  //     border-width: 0 2px 2px 0;
  //     transform: rotate(45deg);
  //     position: absolute;
  //     top: 2px;
  //     left: 4px;
  // }
}

.auth-form-agreement {
  display: flex;
  align-items: center;
  gap: 14px;
  padding-top: 16px;
  cursor: pointer;

  &__input {
    display: none;
  }

  &__checkbox {
    position: relative;
    width: 100%;
    max-width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid #BCBFBE;
    cursor: pointer;

    & svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: none;
    }
  }

  &__text {
    font-family: 'Light';
    font-size: 13px;
    line-height: 16px;

    @media screen and (max-width: 385px) {
      font-size: 10px;      
    }
  }

  &__link {
    color: $main;
  }

  &__input {
    &:checked {
      & ~ .auth-form-agreement {
        &__checkbox {
          border: none;
          background-color: $main;
  
          & svg {
            display: block;

            & > path {
              fill: $white;
            }
          }
        }
      }
    }
  }
}


@media screen and (max-width: $xsBreakpoint) { 
  .auth-form {
    max-width: none !important;
    width: 100% !important;
    margin: 64px 16px 0 16px;
    padding: 24px 16px;

    &-title {
      font-size: 20px;
    }

    &-label {
      font-size: 10px;
      margin: {
        left: 0 !important;
        right: 0 !important;
      }
    }

    &-login-button,
    &-registration-button,
    &-restore-button {
      @include standart-button(100%, 40px);
      margin-top: 30px;
      padding: 8x 24px 8px 24px;

      font-size: 14px;
    }
  }
}