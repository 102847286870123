@import 'helpers';

@import 'header';
@import 'footer';
@import 'auth';
@import 'orders';
@import 'order';
@import 'toogler';
@import 'profile';
@import 'cart';
@import 'input';
@import 'button';
@import 'referral';
@import 'tab-bar';
@import 'partners';
@import 'users';
@import 'user';
@import 'offers';
@import 'offer';
@import 'not-found';
@import 'notifications';
@import 'category.scss';
@import 'nomenclature.scss';
@import 'documents/documents';
@import 'projects';
@import 'project';
@import 'village/village';
@import 'terms/terms';



html {
  font-family: 'Regular';
  font-weight: 400;
  background-color: $bacground;
  margin: 0;
  position: relative;
  min-height: 100vh;
  color: $text;
}

body {
  background-color: $bacground;
  margin: 0;
  position: relative;
  min-height: 100vh;

  .main-wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;

    .main {
      flex: 1;
    }
  }

  input:focus {
    outline: none;
  }

  button:focus {
    box-shadow: none;
  }
}