.notifications {
  z-index: 10000;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 160px;
  right: 20px;

  &-item {
    width: 400px;
    background: $white;
    border-radius: 20px;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);

    &-close {
      width: 24px;
    }
  }
}
