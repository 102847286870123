.tab-bar {
  display: none;
}

@media screen and (max-width: $mdBreakpoint) {
  .tab-bar {
    display: block !important;
    position: absolute;
    top: 0;
    background-color: $bacground;
  
    &--menu {
      position: fixed;
      bottom: 0;
      margin-top: 8px;
      border-radius: 20px 20px 0px 0px;
      background-color: $white;
      padding-bottom: 7px;
      a {
        text-decoration: none !important;
        color: $text !important;
      }
      ul {
        padding: 0;
        margin-bottom: 0;
        text-align: center;
        li {
          cursor: pointer;
          display: inline-block;
        }
      }

      &_open {
        height: 100vh;
        margin-top: 173px;
        li {
          display: block !important;
          cursor: pointer;
        }

        li:not(:first-child) {
          font-size: 14px;
          margin-top: 10px;
        }
        li:first-child {
          margin-top: 11px;
        }
      }
    }
  }
}