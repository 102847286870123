@mixin standart-button($width: 176px, $height: 56px) {
  width: $width;
  height: $height;
  border: 0;
  padding: 8px 16px 8px 16px;
  border-radius: 100px;
  background-color: #89A51E;
  color: $white;
  cursor: pointer;
}
