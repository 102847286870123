// .cart {
//   &-page {
//     margin-top: 80px;
//   }
//   &-wrapper {
//     margin-bottom: 341px;
//   }
//   &-block {
//     margin-top: 27px;
//   }
//   &-title {
//     font-size: 32px;
//     font-family: "SemiBold";
//     color: $text;
//     text-align: center;
//   }

//   &--confirmation {
//     button {
//       color: $white;
//       border: none;
//       background-color: $main;
//       cursor: pointer;
//     }
//   }

//   &-empty_block {
//     text-align: center;
//     margin-top: 221px;
//     &__notification {
//       font-size: 32px;
//       font-family: "SemiBold";
//       color: $gray;
//     }

//     &__button {
//       margin-top: 52px;
//       button {
//         @include standart-button(235px, 56px);

//         .p-button-label {
//           font-size: 16px;
//           font-family: "Regular";
//         }
//       }
//       //border-radius: 100px;
//     }
//   }

//   &-active_order {

//   }

//   .orders-detail {
//     &-order_number {
//       display: none;
//     }

//     &-order_date {
//       display: none;
//     }
//   }

//   .order-detail_info--status {
//     margin: 0 !important;
//   }

//   .order-detail_info--price {
//     margin-top: 18px !important;
//   }

//   .order-detail {
//     &_info {
//       padding: 24px;
//       //width: 400px;

//       &--quantity-button {
//         margin-right: 140px;
//       }

//       &--trim {
//         margin-top: 27px;

//         .show-more-option {
//           color: $gray;
//           cursor: pointer;
//         }
//       }
//     }
//     margin-top: 0 !important;
//     margin-bottom: 54px !important;
//     &-order_number {
//       display: none;
//     }

//     &_info--status_btn {
//       display: none;
//     }
  
//     &_info--remove_btn {
//       border: none !important;
//       color: $text !important;
//       text-decoration: underline;
//       cursor: pointer;
//       margin-right: 0 !important;
//       padding-right: 0 !important;
//       text-align: right;
//     }
//   }

//   &-prices {
//     padding: 16px 8px 16px 8px;
//     background-color: $white;
//     width: 384px;
//     border-radius: 20px;

//     &--price {
//       margin: 0 16px 0 16px;
//     }

//     &--price:nth-child(1) {
//       font-size: 24px;
//     }

//     &--price:nth-child(2) {
//       font-size: 32px;
//     }

//     &--count {
//       margin: 24px 16px 0 16px;
//     }

//     &--button {
//       margin-top: 25px;
//       button {
//         @include standart-button(204px, 56px);
//       }
//     }
//   }

//   &-prev_order {
//     border: 1px solid $gray;
//     border-radius: 20px;
//     // &--title {
//     //   font-size: 24px;
//     //   font-family: "SemiBold";
//     // }

//     // &--price {
//     //   font-size: 24px;
//     //   font-family: "Bold";
//     // }

//     // &--button {
//     //   button {
//     //     @include standart-button(189px, 56px);
//     //     background-color: $bacground;
//     //     border: 1px solid $gray;
//     //     color: $text;
//     //   }
//     // }
//   }

//   &--completion {
//     // &-title {
//     //   margin-bottom: 32px;
//     //   text-align: center;
//     //   span {
//     //     font-size: 24px;
//     //     font-family: "Medium";
//     //   }
//     // }
//     // &_land-selection--dropdown {
//     //   width: 100% !important;
//     // }
//     // .partners-select {
//     //   .p-highlight {
//     //     background-color: $main !important;
//     //     color: $white !important;
//     //   }
//     // }
//     // &-form {
//     //   margin-top: 0 !important;
//     //   padding-top: 0 !important;
//     // }
//     &_account-type {
//       &_button {
//         .account-type-select {
//           flex-direction: unset !important;
//         }
//         .p-button {
//           height: 58px;
//           width: 155px;
//           border-radius: 100px !important;
//           border: 1px solid $main !important;
//         }
//       }
//     }
//   }
// }


.cart {
  padding: 32px 40px;

  @media screen and (max-width: 500px) {
    padding: 40px 16px;
  }

  &__wrapper {
    width: 100%;
    max-width: 1840px;
    margin: 0 auto;
  }

  &__title {
    margin: 0 0 40px;
    font-family: 'Medium';
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;

    @media screen and (max-width: 500px) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  &-empty_block {
    text-align: center;
    margin-top: 220px;

    &__notification {
      font-size: 32px;
      line-height: 40px;
      font-family: "SemiBold";
      font-weight: 600;
      color: $gray;

      @media screen and (max-width: 1280px) {
        font-family: 'Medium';
        font-weight: 500;
      }
    }
  
    &__button {
      margin-top: 40px;
      button {
        @include standart-button(235px, 56px);
  
        .p-button-label {
          font-size: 16px;
          font-family: "Regular";
          font-weight: 400;
        }
      }
      //border-radius: 100px;
    }
  }
}

.cart-main {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;

  @media screen and (max-width: 1279px) {
    flex-direction: column;
  }

  &__items {
    width: 100%;
    max-width: 1172px;
    min-width: 792px;

    @media screen and (max-width: 1279px) {
      max-width: none;
      min-width: 100%;
    }
  }
  
  &__prices {
    width: 100%;
    max-width: 500px;
    min-width: 384px;

    @media screen and (max-width: 1279px) {
      max-width: 400px;
      margin-left: auto;
    }

    @media screen and (max-width: 768px) {
      max-width: 100%;
    }

    @media screen and (max-width: 450px) {
      min-width: 100%;
    }
  }
}

.cart-items {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.cart-item {
  padding: 24px;
  background-color: $white;
  border-radius: 20px;

  @media screen and (max-width: 500px) {
    padding: 16px 12px;    
  }
}

.cart-item-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 32px;

  @media screen and (max-width: 1279px) {
    justify-content: space-between;
  }

  @media screen and (max-width: 870px) {
    flex-direction: column;
    align-items: center;
  }

  &__img {
    border-radius: 15px;
    width: 100%;
    max-width: 500px;
    min-width: 303px;
    height: auto;
    object-fit: cover;

    @media screen and (max-width: 350px) {
      min-width: 100%;
      max-height: 180px;
    }
  }

  &__info {
    width: 100%;
    max-width: 590px;
    min-width: 374px;

    @media screen and (max-width: 450px) {
      min-width: 100%;
      max-width: 100%;
    }
  }
}

.cart-item-info {
  display: flex;
  flex-direction: column;
  gap: 43px;

  @media screen and (max-width: 500px) {
    gap: 16px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    margin: 0;
    font-family: 'SemiBold';
    font-weight: 600;
    font-size: 32px;

    @media screen and (max-width: 500px) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__deleted {
    font-size: 16px;
    line-height: 24px;
    text-decoration: underline;
    cursor: pointer;
  }

  &__calculator {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 450px) {
      gap: 16px 30px;
      flex-wrap: wrap;
    }
  }
}

.cart-item-materials {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media screen and (max-width: 500px) {
    gap: 8px;
  }
}

.cart-item-materials-item {
  &__text {
    font-size: 16px;
    line-height: 16px;
    color: $text;
  }

  &__more {
    display: block;
    width: max-content;
    color: $gray;
    position: relative;
    cursor: pointer;

    &::before {
      position: absolute;
      content: '';
      border: solid $gray;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      top: 6px;
      right: -17px;
      transform: rotate(45deg);
    }
    
    &--hidden {
      margin-top: 12px;
      
      &::before {
        top: 10px;
        transform: rotate(-135deg);
      }

      @media screen and (max-width: 500px) {
        margin-top: 8px;
      }
    }
  }
}

.cart-item-info-calculator {
  &__button {
    font-family: 'SemiBold';
    font-weight: 600;

    & > .button--quantity {
      align-items: center;
    }
  }

  &__price {
    font-family: 'Medium';
    font-weight: 500;
    font-size: 24px;
    line-height: 40px;

    @media screen and (max-width: 450px) {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

.cart-prices {
  padding: 16px;
  background-color: $white;
  border-radius: 16px;
  position: sticky;
  top: 20px;

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    max-width: 468px;

    @media screen and (max-width: 768px) {
      max-width: none;
    }
  }

  &__total {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 16px;
    background-color: $main;
    border-radius: 100px;
    border: none;
  }
}

.cart-prices-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Medium';
  font-weight: 500;

  @media screen and (max-width: 450px) {
    column-gap: 50px;
    flex-wrap: wrap;
  }

  &__text {
    font-size: 24px;
    line-height: 24px;
  }

  &__summ {
    font-size: 30px;
    line-height: 30px;

    @media screen and (max-width: 450px) {
      font-size: 20px;
      line-height: 24px;
    }
  }
}

.cart-prices-counts {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__text {
    font-family: 'Regular';
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
  }

  &__price {
    font-family: 'Regular';
    font-weight: 400;
    font-style: 16px;
    line-height: 16px;
  }
}

.card-dialog {
  max-width: 500px;
  width: 90% !important;
  border-radius: 20px;
  height: auto;
}


.card-form {
  background-color: $white;
  width: 100%;
  padding: 32px;
  border-radius: 20px;

  &-title {
    font-size: 24px;
    line-height: 20px;
  }

  &-description {
    font-size: 16px;
    line-height: 25px;
    margin-top: 40px;
    margin-bottom: 12px;
  }

  &-label {
    color: $text;
    font-size: 16px;
    margin-top: 20px;
  }

  &-input {
    width: 100%;
    height: 66px;
    border: none;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1) !important;
    border-radius: 10px;
    padding: {
      left: 10px;
      right: 40px;
    }

    &-error {
      border: solid $warning !important;
    }

    &::-webkit-input-placeholder {
      color: #bcbfbe;
      font-size: 14px;
    }

    &::-moz-placeholder {
      color: #bcbfbe;
      font-size: 14px;
    }

    &:-ms-input-placeholder {
      color: #bcbfbe;
      font-size: 14px;
    }

    &::-ms-input-placeholder {
      color: #bcbfbe;
      font-size: 14px;
    }

    &::placeholder {
      color: $gray;
      font-size: 14px;
    }

    &:disabled {
      background: $white;
    }
  }

  &-registration-button {
    margin-top: 30px;
    padding: 16px 40px 16px 40px;
    @include standart-button(241px, 56px);
  }

  &-login-button {
    @include standart-button(127px, 56px);
    margin-top: 30px;
    padding: 16px 40px 16px 40px;
  }

  &-select-button {
    background: inherit;
    color: $text;
    width: 155px;
    border: 1px solid $main !important;
  }

  &-control-buttons {
    margin-top: 32px;

    &--prev,
    &--next {
      min-width: 125px;
    }

    &--next {
      background-color: $main;
      border: none !important;
    }

    &--prev {
      background: inherit;
      color: $disable;
      border: 1px solid $disable;
    }
  }

  &-forget {
    margin-top: 16px;
    font-size: 10px;
    font-family: "Regular";
    cursor: pointer;
  }

  button {
    padding: 16px 40px 16px 40px;
    min-height: 56px;
    border-radius: 100px;
    width: 100%;
  }

  &--registration-button {
    margin-top: 16px;
    text-decoration: underline;
    cursor: pointer;
  }

  &-tos {
    input:checked {
      // background-color: red;
    }

    label {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .auth-form-label {
    .input-container {
      margin-top: 12px;
      padding: 0;
      letter-spacing: 0;

      svg {
        right: 16px;
      }
    }
  }

  .input-container {
    input {
      font-size: 14px;
    }
    .input-error-info {
      border: 2px solid $warning;
    }
    input:focus{
      outline: none;
    }

    .input-password {
      letter-spacing: 0;
      padding-right: 80px;

      &-icon {
        right: 16px;
      }
    }
  }

  &-restore-button {
    @include standart-button(129px, 56px);
    margin-top: 30px;
    padding: 16x 40px 16px 40px;
  }

  &--restore {
    max-width: 500px !important;
    padding-left: 21px !important;
    padding-right: 21px !important;

    .auth-form-label {
      margin-left: 36px;
      margin-right: 36px;
    }

    &-pending {
      text-align: center;
    }
    &-info {
      margin-top: 40px;
      font-size: 24px;
      font-family: "Medium";
    }

    &-description,
    &-timer {
      margin-top: 32px;
    }

    &-timer {
      &--link {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .p-dialog-header {
    padding: 10px;
  }

  .cart--completion-title {
    text-align: center;
    font-family: 'Medium';
    font-weight: 500;
  }
}

// костыли потом убрать
.cart {
  &--completion {
    &_account-type {
      &_button {
        .account-type-select {
          flex-direction: unset !important;
        }
        .p-button {
          height: 58px;
          width: 155px;
          border-radius: 100px !important;
          border: 1px solid $main !important;
        }
      }
    }
  }
}