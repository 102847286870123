@font-face {
  font-family: "Light";
  src:
    url("../fonts/Cygre-Light.woff2") format("woff2"),
    url("../fonts/Cygre-Light.woff") format("woff");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Regular";
  src:
    url("../fonts/Cygre-Regular.woff2") format("woff2"),
    url("../fonts/Cygre-Regular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Medium";
  src:
    url("../fonts/Cygre-Medium.woff2") format("woff2"),
    url("../fonts/Cygre-Medium.woff") format("woff");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "SemiBold";
  src:
    url("../fonts/Cygre-SemiBold.woff2") format("woff2"),
    url("../fonts/Cygre-SemiBold.woff") format("woff");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Bold";
  src:
    url("../fonts/Cygre-Bold.woff2") format("woff2"),
    url("../fonts/Cygre-Bold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
}