.user {
  &-card {
    background-color: $white;
    border-radius: 20px;
    margin: 40px;
    padding: 24px;
    &--info {
      height: 224px;
      &_photo {
        width: 180px;
        height: 100%;
        img {
          height: 100%;
        }
      }
      &_fields {
        margin-left: 19px;
        &-item {
          span {
            display: block;
          }

          span:nth-child(1) {
            font-size: 14px;
            font-family: "Regular";
          }

          span:nth-child(2) {
            margin-top: 8px;
          }
        }
      }
    }

    &--orders {
      button {
        @include standart-button(132px, 56px);
        cursor: pointer;
        font-size: 16px;
        font-family: "Regular";
      }
    }

    &--role {
      margin-top: 20px;
      button {
        @include standart-button(180px, 40px);
        cursor: pointer;
        background-color: $white;
        color: $text;
        border: 1px solid $main;
        font-size: 16px;
        font-family: "Regular";
      }
    }

    &--referal {
      margin-top: 20px;
      &_link {
        margin-left: 24px;
        height: 37px;
        border-radius: 20px;
        box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
        border: none;
        text-align: center;
      }
    }

    &--table {
      margin-top: 16px;
      .p-datatable-wrapper {
        border-radius: 20px;
      }

      .p-datatable {
        width: 100%;
      }

      .p-datatable {
        .p-datatable-header {
          border-radius: 20px, 20px, 0px, 0px;
          background-color: $bacground;
        }
        .p-column-title {
          font-size: 14px;
          font-family: "Regular";
        }

        .p-datatable-footer {
          background-color: $white;
          border-radius: 0 0 20px 20px;
          padding: 1rem 1rem;
        }
      }

      &-footer {
        border-radius: 20px;
        font-size: 16px;
        color: $text;
        font-family: "Regular";
        
        &-item_amount {
          margin-left: 20px;
          font-size: 24px;
          color: $main;
        }
      }

      &-link {
        text-align: right;
        button {
          @include standart-button(90px, 40px);
          cursor: pointer;
          background-color: $white;
          color: $text;
          border: 1px solid $main;
          font-size: 14px;
          font-family: "Regular";
        }
      }

      & .p-datatable .p-datatable-thead > tr > th, td {
        padding: 1rem 1rem;
      }
    }
  }
}