.button {
  &-back {
    max-width: fit-content;
    border: none;
    background: transparent;
    cursor: pointer;
    display: flex;
    gap: 5px;
    align-items: center;
    font-family: "Medium";
    font-size: 16px;

    &:active {
      opacity: 0.6;
    }

    svg {
      width: 16px;
    }
  }

  &-green {
    background-color: $main;
    border: none;
    color: $white;
    border-radius: 100px;
  }

  &-white {
    background-color: $white;
    border: solid 1px $main;
    color: $text;
    border-radius: 100px;
  }

  &--load-more {
    margin-top: 30px;
    button {
      @include standart-button(165px, 40px);
      cursor: pointer;
    }
  }

  &--quantity {
    width: 129px;
    height: 40px;
    line-height: 33px;
    border-radius: 20px;
    border: 1px solid $gray;
    &_minus {
      margin-left: 16px;
      cursor: pointer;
    }

    &_plus {
      margin-right: 16px;
      cursor: pointer;
    }

    &_count {
      margin: 0 16px 0 16px;
    }
  }
}
