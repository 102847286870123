.projects {
  padding: 40px 20px;

  @media screen and (max-width: 800px) {
    padding: 20px 16px;
  }

  @media screen and (max-width: 360px) {
    padding: 20px 10px;
  }

  &__wrapper {
    margin: 0 auto;
    width: 100%;
    max-width: 1840px;
    
    @media screen and (max-width: 1280px) {
      max-width: 1160px;
    }

    @media screen and (max-width: 1199px) {
      max-width: 1120px;
    }
  }

  
}

.projects-navigation {
  &__swiper {
    width: 100%;
    max-width: 1840px;
    height: 150px;
    margin: 0 auto 40px;
    overflow: hidden;

    @media screen and (max-width: 1600px){
      width: 1200px;
      height: 172px;
    }

    @media screen and (max-width: 1279px) {
      width: 930px;
    }

    @media screen and (max-width: 1049px) {
      width: 700px;
    }

    @media screen and (max-width: 743px) {
      width: 450px;
    }

    @media screen and (max-width: 529px) {
      width: 100%;
    }
  }
}

.projects-navigation-swiper {
  & .swiper-wrapper {
    animation: none;
  
    @media screen and (max-width: 1049px) {
      animation-duration: 1.5s;
      animation-name: swiper-hover;
    }
  }

  &__slide {
    background-color: $white;
    border-radius: 20px;
    padding: 8px 16px;

    position: relative;
    cursor: pointer;
  }
}

.projects-navigation-swiper-slide {
  &__text {
    display: flex;
    align-items: flex-start;
    font-family: 'SemiBold';
    font-weight: 600;
    gap: 8px;
    color: $main;

    & span {
      display: block;

      &:first-child {
        max-width: 141px;
      }

      &:nth-child(2) {
        font-family: 'Regular';
        font-weight: 400;
        font-size: 10px;
        padding: 10px;
        line-height: 10px;
        padding: 4px;
        text-transform: lowercase;
        border-radius: 5px;
        background-color: $main;
        color: $white;
      }
    }
  }

  &__img {
    position: absolute;
    right: 0;
    bottom: 0;

    &--active {
      path {
        stroke: $main;
      }
    }
  }
} 

.projects-filters {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 27px 0;
  flex-wrap: wrap;

  @media screen and (max-width: 844px) {
    justify-content: center;
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
    color: #000;
  }

  &__slider {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &--area {
      width: 256px;
    }

    &--price {
      width: 393px;
    }

    input {
      width: 50% !important;
      height: 56px !important;
    }

    input:nth-child(1) {
      border-radius: 10px 0 0 10px;
    } 

    input:nth-child(2) {
      border-radius: 0 10px 10px 0;
    } 

    .p-slider {
      width: 90% !important;
      margin: 0 auto;

      .p-slider-range {
        background: $main;
      }

      .p-slider-handle {
        border-color: $main !important;
      }
    }

    @media screen and (max-width: 440px) {
      display: none;
    }
  }
}

.projects-filters-floors {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (max-width: 440px) {
    display: none;
  }
}

.projects-filters-floors-buttons {
  display: flex;
  align-items: center;
  gap: 10px;

  &__button {
    font-size: 16px;
    line-height: 24px;
    font-family: "SemiBold";
    padding: 16px 24px;
    background-color: $white;
    border: none;
    border-radius: 10px;

    &--active {
      color: $white;
      background-color: $main;
    }
  }
}

.projects-list {
  &__wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 40px 48px;
    margin: 0 auto;

    @media screen and (max-width: 1880px) {
      gap: 48px 66px;
      max-width: 1160px;
    }

    @media screen and (max-width: 1279px) {
      gap: 20px 20px;
      max-width: 1068px;
    }

    @media screen and (max-width: 1199px) {
      gap: 20px 20px;
    }

    @media screen and (max-width: 1160px) {
      gap: 20px 20px;
      max-width: 740px;
    }

    @media screen and (max-width: 800px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px 8px;
    }
  }

  &__more {
    width: 100%;
    max-width: max-content;
    margin: 152px 0 0 auto;
    padding: 16px 40px;
    background-color: $main;
    color: $white;
    border-radius: 100px;
    font-size: 16px;
    line-height: 24px;
  }
}

.projects-list-item {
  cursor: pointer;
  
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    border-radius: 20px;
    width: 424px;
    background-color: $white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

    @media screen and (max-width: 1880px) {
      width: 100%;
      max-width: 352px;
    }

    @media screen and (max-width: 800px) {
      max-width: max-content;
    }

    @media screen and (max-width: 600px) {
      gap: 8px 0; 
    }

    @media screen and (max-width: 440px) {
      max-width: 300px;
    }

    @media screen and (max-width: 440px) {
      max-width: 300px;
    }
  }

  &__img {
    border-radius: 20px;
    width: 100%;
    height: 257px;

    @media screen and (max-width: 800px) {
      height: auto;
    }

    @media screen and (max-width: 440px) {
      max-width: 300px;
    }
  }

  &__main {
    margin: 0 8px;
  }

  &__info {
    border-top: 1px dashed #B2B2B2;
  }
}

.projects-list-item-main {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__title {
    font-family: "Medium";
    font-weight: 500;
    color: $icon;
    font-size: 24px;
    line-height: 30px;
    margin: 0;

    @media screen and (max-width: 600px) {
      font-size: 14px;
    }

    @media screen and (max-width: 450px) {
      font-size: 10px;
      line-height: 12px;
    }
  }

  &__amount {
    color: $icon;
    font-size: 16px;
    line-height: 30px;

    @media screen and (max-width: 600px) {
      font-size: 10px;
    }

    @media screen and (max-width: 450px) {
      font-size: 8px;
      line-height: 12px;
    }
  }
}

.projects-list-item-info {
  display: flex;
  align-items: center;
  justify-content: space-around;

  &__front, 
  &__area,
  &__floor {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 14px;
    padding: 20px 0;
    width: 100%;
    max-width: 120px;

    @media screen and (max-width: 600px) {
      padding: 5px 6px;
      gap: 2px;

      & svg {
        width: 12px;
        height: 12px;
      }

      & span {
        font-size: 8px;
        line-height: 10px;
      }
    }

    @media screen and (max-width: 600px) {
      padding: 5px 2px;
      gap: 2px;
    }
  }

  &__area {
    border-right: 1px dashed #B2B2B2;
    border-left: 1px dashed #B2B2B2;
  }
  
}

.projects-filters {
  &__search {
    width: 100%;
    max-width: 300px;
  }
}

.projects-filters-search {
  @media screen and (min-width: 441px) {
    display: none;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 10px 12px;
  background-color: $white;
  border: 1px solid #89a51e;
  border-radius: 100px;
  cursor: pointer;
  color: $text;

  &__text {
    font-size: 14px;
    line-height: 24px;
  }

  &__icon {
    width: 18px;
    height: 18px;
  }
}

.projects-filters-modal {
  width: 100%;
  max-width: 489px;
  height: auto;
  border-radius: 20px;

  &__wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 100%;
    gap: 30px;
  }

  &__floors {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
    color: #000;
  }

  &__slider {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &--area {
      width: 256px;
    }

    &--price {
      width: 100%;
    }

    input {
      width: 50% !important;
      height: 56px !important;
    }

    input:nth-child(1) {
      border-radius: 10px 0 0 10px;
    } 

    input:nth-child(2) {
      border-radius: 0 10px 10px 0;
    } 

    .p-slider {
      width: 90% !important;
      margin: 0 auto;

      .p-slider-range {
        background: $main;
      }

      .p-slider-handle {
        border-color: $main !important;
      }
    }
  }

  &__button {
    padding: 17px 40px;
    border: 1px solid $main;
    border-radius: 100px;
    margin-top: 32px;
    background-color: $main;
    color: $white;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
  }

  & .p-dialog-title {
    font-size: 20px;
    line-height: 24px;
    color: #000;
  }

  & .p-dialog-header-close {
    path{
      fill: $icon;
    }
  }

  & .p-dialog-header {
    border-radius: 20px 20px 0 0;
    background-color: $bacground;
  }

  & .p-dialog-content {
    border-radius: 0 0 20px 20px;
    background-color: $bacground;
  }
}

.projects-filters-modal-floors-buttons {
  display: flex;
  align-items: center;
  gap: 10px;

  &__button {
    font-size: 16px;
    line-height: 24px;
    font-family: "SemiBold";
    padding: 16px 24px;
    background-color: $white;
    border: none;
    border-radius: 10px;

    &--active {
      color: $white;
      background-color: $main;
    }
  }
}

// .projects-filters-modal-input {
//   &__wrapper {
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//     margin-bottom: 14px;
//     gap: 12px;
//     position: relative;
//   }

//   &__lable {
//     font-size: 16px;
//     line-height: 24px;
//     color: #000;
//   }

//   &__item {
//     color: $text;

//     &--dropdown {
//       padding: 18px 13px;
//       font-size: 14px;
//       line-height: 20px;

//       & * {
//         padding: 0;
//       }
//     }
//   }

//   &__search {
//     font-size: 14px;
//     line-height: 20px;
//     color: $text;
//     padding: 18px 13px;
//     width: 100%;
//     margin-bottom: 40px;
//   }

//   &__icon {
//     position: absolute;
//     z-index: 1;
//     width: 18px;
//     height: 18px;
//     top: 19px;
//     right: 16px;

//     svg {
//       width: 18px;
//       height: 18px;
//     }
//   }
// }