.partners {
  &-item {
    border-radius: 20px;
    background: $white;
    padding: 24px;

    &-tag {
      border-radius: 8px;
      border: 1px solid $main;
      color: $main;
      padding: {
        top: 8px;
        bottom: 8px;
        right: 16px;
        left: 16px;
      }
    }
  }

  &-select {
    &.p-selectbutton {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .p-button {
        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1) !important;
        border-radius: 10px;
        height: 66px;
        border: none;
      }

      .p-highlight {
        background: unset;
        color: unset;
        border: 1px solid #aeb2b1;
      }
    }
  }

  &-checkbox {
    &.p-checkbox.p-highlight .p-checkbox-box {
      background: $main;
      background-color: $main;
      border-color: $main;
    }
    &.p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box {
      border: 2px solid $main;
    }
  }

  &-issue {
    max-width: 500px;
    width: 100%;
  }
}

.partners-filters {
  &__button {
    padding: 0.5rem 1rem;
  }

  & > .p-button {
    padding: 0.5rem 1rem;
  }
}