.order {
  &-detail {
    &--wrapper {
      border-radius: 20px;
      padding-bottom: 20px;
      background-color: $white;
      border-radius: 20px;
      margin-right: 50px;
    }
  }
  .executors-detail {
    .active-list {
      border: 1px solid $main;
    }
    margin: 0;
    padding: 0;
    &--title {
      text-align: center;
      font-family: "SemiBold";
      font-size: 20px;
      margin-bottom: 24px;
      color: #000000;
    }

    .order--inputs {
      width: auto;
      padding: 0;
      max-width: none;
    }

    .p-listbox {
      border-radius: 8px;
    }
  }
  .executor-button {
    width: 253px !important;
  }
  &--inputs {
    border-radius: 20px;
    &_buttons {
      text-align: right;
      padding: 8px 16px 8px 0;
      button {
        @include standart-button(150px, 42px);
      }
    }

    &-title {
      font-size: 24px;
      font-family: "Medium";
    }

    &-documents {
      &>ul {
        width: 100%;
      }
      margin-top: 32px;
      &-item {
        position: relative;
        margin-bottom: 8px;
        height: 48px;
        box-shadow: 0px 4px 6px 0px #0000001A;
        border: none;
        border-radius: 10px;
        display: flex;
        align-items: center;
        padding: 12px 32px;
        &_name,
        &_link {
          display: inline-block;
        } 
        &_name {
          //position: absolute;
          width: 90%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-left: 20px;
        }

        &-del {
          display: none;
          right: 32px;
          position: absolute;
          cursor: pointer;
        }

        &_link {
          width: 100%;
          padding-left: 120px;
        }

        &:hover {
          .order--inputs-documents-item-del {
            display: block;
          }
        }
      }

      &-add_button {
        cursor: pointer;
        text-decoration: underline;
      }

      &-save_button {
        margin-top: 32px;
        button {
          @include standart-button(253px, 58px);
        }
      }

      & > .select {
        width: 100%;
      }
    }
  }
  &--dialog {
    width: 489px !important;
    height: auto;
    border-radius: 20px;

    .p-dialog-header {
      border-radius: 20px 20px 0 0;
    }

    .p-dialog-content {
      border-radius: 0 0 20px 20px;
    }
  }

  &--form {
    margin: 0;
    padding: 0;

    button {
      width: 253px;
      height: 58px;
    }
  }

  &--select {
    &-buttons {
      text-align: center;
      button {
        @include standart-button(253px, 58px);
      }
    }
  }
}

.order-detail_inputs--list__date {
  padding: 1rem;
  color: $text;
  background: $white;
  font-weight: 600;
  border-radius: 3px;
  transition: box-shadow 0.2s;
  margin-bottom: 15px;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
}

.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  margin-right: 0.5rem;
}

.order-inputs-documents {
  &__or {
    margin: 32px auto;
    font-family: 'Regular';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}

.order-inputs-documents-alternative {
  &__title {
    font-family: 'Regular';
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  &__suptitle {
    font-family: 'Regular';
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $gray;
  }

  &__input {
    width: 100%;
    padding: 16px 21px;
    border-radius: 10px;
    border: none;
    box-shadow: 0px 4px 6px 0px #0000001A;
    margin: 12px auto 32px;
    color: $text;
    font-family: 'Regular';
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    &--invalid {
      border: 1px solid #F4970C;
    }

    &::-webkit-input-placeholder {
      color: $gray;
    }

    &::-moz-placeholder {
      color: $gray;
    }

    &:-ms-input-placeholder {
      color: $gray;
    }

    &::-ms-input-placeholder {
      color: $gray;
    }

    &::placeholder {
      color: $gray;
    }
  }

  &__added {
    cursor: pointer;
    .documents-added-modal-form__input--file {
      width: 100% !important;
      height: 100% !important;
      z-index: 20 !important;
    }
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    font-size: 16px;
    line-height: 24px;
    color: #89a51e;
    text-align: center;
    border-radius: 12px;
    background-color: #ffffff;
    width: 100%;
    padding: 24px;
    border: 2px dashed #89a51e;
    margin: 0 auto 8px;
  }
}