.nomenclature {
  padding: 32px 40px;

  &__header {
    max-width: 1840px;
    margin: 10px auto 0;

    @media screen and (max-width: 1280px) {
      max-width: 1200px;
    }
  }

  &__empty {
    width: 100%;
    max-width: 1200px;
    height: 569px;
    margin: 0 auto;
    border-radius: 20px;
  }
}

.nomenclature-header {
  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: 560px) {
      align-items: flex-start;
      gap: 20px;
      flex-direction: column;
    }
  }

  &__title {
    font-family: 'Medium';
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
  }

  &__button {
    border: none;
    background-color: $main;
    color: $white;
    padding: 17px 29px;
    border-radius: 100px;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;

    @media screen and (max-width: 560px) {
      font-size: 14px;
      line-height: 20px;
      width: 100%;
    }
  }
}

.nomenclature-empty {
  position: relative;
  background-color: $white;
  padding: 32px 40px;

  &__placeholder {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 32px;
    line-height: 53px;
    font-weight: 700;
    color: #BCBFBE;
  }

  &__button {
    position: absolute;
    bottom: 40px;
    right: 32px;
    border: none;
    background-color: $main;
    color: $white;
    padding: 17px 29px;
    border-radius: 100px;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
  }
}