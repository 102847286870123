// .project {
//   margin-top: 80px;
//   &--options {
//     margin-left: 50px;
//     padding: 16px;
//     max-width: 472px;
//     border-radius: 20px;
//     background-color: $white;
//     &__header {

//       &--title {
//         font-family: "SemiBold";
//         font-size: 32px;
//       }

//       &--amount {
//         font-weight: 500;
//         font-size: 24px;
//       }
//     }

//     &__description {
//       margin-top: 32px;
//       span {
//         font-weight: 500;
//         font-size: 24px;
//       }
//     }

//     &__list {
//       margin-top: 32px;
//       &--item {
//         border-bottom: 10px;
//         &_name {
//           font-family: "SemiBold";
//         }

//         &_showmore {
//           color: $main;
//           cursor: pointer;
//         }

//         &_options {
//           margin-top: 16px;
//           button {
//             @include standart-button(auto, 42px);
//             padding: 8px 24px 8px 24px;
//             font-size: 14px;
//             font-weight: 400;
//             margin-right: 8px;
//             color: $text;
//             background-color: $white;
//             border: 1px solid $main;
//             margin-bottom: 8px;
//           }
//           button.active {
//             border: none;
//             background-color: $main;
//             color: $white
//           }
//         }
//       }
//     }

//     &__summary {
//       margin-top: 16px;
//       &--title {
//         font-family: "SemiBold";
//         margin-bottom: 16px;
//       }

//       &--list {
//         margin-bottom: 10px;
//         &__label {
//           color: $gray;
//           font-weight: 400;
//         }
//       }
//     }
//   }

//   &--gallery {
//     margin-right: 50px;
//     &__switcher {
//       button {
//         @include standart-button(160px, 42px);
//         padding: 8px 24px 8px 24px;
//         font-size: 14px;
//         font-weight: 400;
//         margin-right: 8px;
//         color: $text;
//         background-color: $white;
//         margin-bottom: 8px;
//         border: 1px solid #C9CDD4;
//       }
//       button.active {
//         border: 1px solid $main;
//       }
//     }
//   }
// }

.project {
  padding: 20px;
  margin: 0;

  &__header {
    width: 100%;
    max-width: 1840px;
    margin: 0 auto;
  }

  &__wrapper {
    width: 100%;
    max-width: 1720px;
    margin: 0 auto;
  }

  &__back {
    margin-bottom: 20px;
  }

  &__swiper {
    width: 100%;
    height: 150px;
    margin: 0 auto 40px;
    overflow: hidden;

    @media screen and (max-width: 1600px){
      width: 1200px;
      height: 172px;
    }

    @media screen and (max-width: 1279px) {
      width: 930px;
    }

    @media screen and (max-width: 1049px) {
      width: 700px;
    }

    @media screen and (max-width: 743px) {
      width: 450px;
    }

    @media screen and (max-width: 529px) {
      width: 100%;
    }
  }
}

.project-back {
  display: flex;
  align-items: center;
  gap: 8px;
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;

  &__icon {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 2px;
  
      & svg {
        width: 15px;
      }
    }
  
    &__text {
      font-size: 14px;
      margin: 4px 0;
    }
}

.project-swiper {
  & .swiper-wrapper {
    animation: none;

    @media screen and (max-width: 1049px) {
      animation-duration: 1.5s;
      animation-name: swiper-hover;
    }
  }

  &__slide {
    background-color: $white;
    border-radius: 20px;
    padding: 8px 16px;

    position: relative;
    cursor: pointer;
  }
}

.project-swiper-slide {
  &__text {
    display: flex;
    align-items: flex-start;
    font-family: 'SemiBold';
    font-weight: 600;
    gap: 8px;
    color: $main;

    & span {
      display: block;

      &:first-child {
        max-width: 141px;
      }

      &:nth-child(2) {
        font-family: 'Regular';
        font-weight: 400;
        font-size: 10px;
        padding: 10px;
        line-height: 10px;
        padding: 4px;
        text-transform: lowercase;
        border-radius: 5px;
        background-color: $main;
        color: $white;
      }
    }
  }

  &__img {
    position: absolute;
    right: 0;
    bottom: 0;

    &--active {
      path {
        stroke: $main;
      }
    }
  }
}

.project-main {
  padding: 80px 0 40px;
  display: grid;
  align-items: start;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;

  @media screen and (max-width: 744px) {
    gap: 8px;
  }
  
  @media screen and (max-width: 740px) {
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 600px) {
    gap: 16px;
  }
}

.project-gallery { 
  position: -webkit-sticky; 
  position: sticky;
  top: 5px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (max-width: 744px) {
    gap: 8px;
  }

  @media screen and (max-width: 740px) {
    position: static;
  }

  &__wrapper {
    position: relative;

    .swiper-slide {
      width: 100% !important;
    }
  }

  &__img {
    width: 100%;
    max-width: 1022px;
    height: 100%;
    max-height: 700px;
    border-radius: 20px;
  }

  &__buttons {
    position: absolute;
    z-index: 2;
    left: 16px;
    bottom: 16px;
    display: flex;

    @media screen and (max-width: 744px) {
      position: static;
      width: max-content;
      margin: 8px auto 0;
    }

    @media screen and (max-width: 600px) {
      margin-top: 16px;
    }
  }

  &__description {
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }
}

.project-gallery-buttons {
  display: flex;
  align-items: center;
  gap: 16px;

  &__item {
    width: 120px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    border: 1px solid $main;
    background-color: $white;
    cursor: pointer;

    &--active {
      color: $white;
      background-color: $main;
    }
  }
}

.project-description { 
  padding: 24px 32px 18px;
  background-color: $white;
  border-radius: 20px;

  @media screen and (max-width: 600px) {
    display: none;
  }

  &__text {
    margin: 0;
    height: 100%;
  }
}

.project-options { 
  position: -webkit-sticky; 
  position: sticky;
  top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 740px) {
    position: static;
    width: 100%;
  }

  &__wrapper {
    padding: 24px 32px;
    background-color: $white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 24px;
  }

  &__subtitle {
    font-family: "SemiBold";
    font-weight: 600;
    font-size: 24px;
    line-height: 39px;
    margin: 0 0 10px;

    @media screen and (max-width: 500px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 10px;
  }

  &__button {
    width: 100%;
    background-color: $main;
    color: $white;
    padding: 16px;
    text-align: center;
    border-radius: 100px;
    margin-top: 42px;
    cursor: pointer;

    @media screen and (max-width: 600px) {
      margin-top: 16px;
    }
  }
  
  &__summary {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: auto;
  }
}

.project-options-header {
  &__title {
    margin: 0;
    font-size: 32px;
    line-height: 53px;
    font-family: "Medium";
    font-weight: 500;
    display: block;
    width: max-content;

    @media screen and (max-width: 600px) {
      line-height: 40px;
    }
  }

  &__amount {
    font-family: "Medium";
    font-weight: 500;
    font-size: 24px;
    line-height: 39px;
    display: block;
    width: max-content;
  }
}

.project-options-list {
  &__item {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.list-item {
  &__name {
    font-family: "SemiBold";
    font-weight: 600;

    @media screen and (max-width: 744px) {
      font-family: 'Medium';
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }
  }

  &__options {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  &__showmore {
    display: block;
    width: 100%;
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
    margin: 16px auto;
    font-family: "SemiBold";
    font-weight: 600;
    color: $main;
    cursor: pointer;
    position: relative;

    &::before {
      position: absolute;
      content: '';
      border: solid $main;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      top: 2px;
      right: -18px;
      transform: rotate(45deg);
    }

    &--close {
      &::before {
        top: 6px;
        transform: rotate(-135deg);
      }
    }
  }
}

.list-item-options {
  &__button {
    padding: 11px 24px;
    background-color: $white;
    border: 1px solid $main;
    font-family: 'Regular';
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    border-radius: 100px;
    cursor: pointer;

    @media screen and (max-width: 600px) {
      font-size: 12px;
      line-height: 16px;
    }

    &--active {
      background-color: $main;
      color: $white;
    }

    &--disabled {
      border: 1px solid $disable;
      color: $disable;
    }
  }
}

.project-options-summary {
  &__title {
    margin: 0;
    font-family: "SemiBold";
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  &__item {
    display: flex;
    justify-content: space-between;
  }
}

.project-options-summary-item {
  &__label {
    color: $gray;
  }

  &__value {
    text-align: right;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    min-width: 70px;
  }
}

.project-program {
  &__title {
    font-size: 32px;
    line-height: 52px;
    font-family: "Medium";
    font-weight: 500;
    margin: 0 0 24px;

    @media screen and (max-width: 600px) {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 8px;
    }
  }

  &__slider {
    width: 100%;
    height: 150px;
    margin: 0 auto;

    @media screen and (max-width: 440px) {
      height: 130px;
    }
  }
}

.project-program-slider {
  & .swiper-wrapper {
    animation: none;

    @media screen and (max-width: 743px) {
      animation-duration: 1.5s;
      animation-name: swiper-hover;
    }
  }
  
  &__item {
    font-size: 18px;
    background: $white;
    position: relative;
    border-radius: 20px;
  }
}

.project-program-slider-item {
  padding: 16px;

  @media screen and (max-width: 800px) {
    padding: 10px;
  }

  @media screen and (max-width: 440px) {
    padding: 12px;
  }

  &__text {
    font-size: 24px;
    line-height: 39px;
    font-family: "Medium";
    font-weight: 500;
    position: relative;
    display: block;
    width: 100%;
    max-width: 245px;
    z-index: 2;

    @media screen and (max-width: 910px) {
      font-size: 20px;
      line-height: 32px;
    }

    @media screen and (max-width: 600px) {
      font-size: 14px;
      line-height: 15px;
    }
  }

  &__img {
    position: absolute;
    width: 120px;
    height: 134px;
    bottom: 0;
    right: 0;
    
    & svg {
      width: 100%;
      height: 100%;
    }

    &--active {
      & svg {
        & path {
          stroke: $main;
        }
      }
    }
  }
}

.project-calculator {
  padding: 40px 0 30px;

  &__wrapper {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 20px;

    @media screen and (max-width: 1200px) {
      gap: 12px;
    }
    
    @media screen and (max-width: 740px) {
      flex-wrap: wrap;
      justify-content: center;
      gap: 8px;
    }
  }

  &__title {
    font-size: 32px;
    line-height: 40px;
    font-family: "Medium";
    font-weight: 500;
    margin: 0 0 24px;
  }

  &__bar,
  &__standard,
  &__reduced {
    width: 100%;
  }

  &__bar {
    min-width: 280px;

    @media screen and (max-width: 740px) {
      margin-bottom: 32px;
    }
  }
  
  &__description {
    font-family: 'Regular';
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-top: 24px;
    color: $gray;

    @media screen and (max-width: 600px) {
      margin-top: 16px;
    }

    @media screen and (max-width: 450px) {
      margin-top: 8px;
      font-size: 10px;
      line-height: 12px;
    }
  }
}

.project-calculator-bar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;

  @media screen and (max-width: 740px) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    width: 100%;
  }
}

.project-calculator-bar-price,
.project-calculator-bar-initial,
.project-calculator-bar-time {
  &__lable {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 18px;
    line-height: 25px;
    font-family: 'Regular';
    font-weight: 400;

    @media screen and (max-width: 1280px) {
      font-size: 10px;
      line-height: 16px;
    }
  }

  &__input {
    padding: 14px 17px;
    font-family: "SemiBold";
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
}

.project-calculator-bar-initial {
  &__lable {
    position: relative;
  }

  &__text {
    font-family: 'Regular';
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin: 10px 0 0;

    @media screen and (max-width: 1280px) {
      font-size: 10px;
      line-height: 16px;
    }
  }
}

.project-calculator-standard,
.project-calculator-reduced {
  padding: 24px 32px;
  border-radius: 20px;

  @media screen and (max-width: 975px) {
    padding: 24px 10px;
  }

  @media screen and (max-width: 740px) {
    width: 100%;
    max-width: 48%;
  }
  
  &__title {
    font-family: "Medium";
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    margin: 0 0 16px;

    @media screen and (max-width: 930px) {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 8px;
    }
  }

  &__percent,
  &__payment,
  &__overpayment {
    margin-bottom: 17px;

    @media screen and (max-width: 930px) {
      margin-bottom: 8px;
    }
  }
}

.project-calculator-standard {
  background-color: $white;
}

.project-calculator-reduced {
  background-color: $main;
  color: $white;

  & br {
    display: none;

    @media screen and (max-width: 383px) {
      display: block;
    }
  }
}

.project-calculator-standard-percent,
.project-calculator-standard-payment,
.project-calculator-standard-overpayment,
.project-calculator-reduced-percent,
.project-calculator-reduced-payment,
.project-calculator-reduced-overpayment {
  display: flex;
  flex-direction: column;
  
  &__title {
    font-family: 'Regular';
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;

    @media screen and (max-width: 930px) {
      line-height: 12px;
    }
  }

  &__value {
    font-family: "Medium";
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin: 3px 0;

    @media screen and (max-width: 930px) {
      font-size: 14px;
    }
  }

  &__subtitle {
    font-size: 12px;
    line-height: 20px;
    position: relative;
    margin-left: 23px;

    @media screen and (max-width: 450px) {
      font-size: 8px;
      line-height: 10px;
      margin-left: 15px;
    }
  }
}

.project-calculator-standard-saving,
.project-calculator-reduced-saving {
  display: flex;
  align-items: center;
  gap: 18px;

  @media screen and (max-width: 930px) {
    gap: 8px;
  }

  &__value {
    font-family: "SemiBold";
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;

    @media screen and (max-width: 930px) {
      font-size: 14px;
      line-height: 24px;
    }

    @media screen and (max-width: 340px) {
      font-size: 13px;
    }
  }

  &__text {
    padding: 5px 10px;
    border-radius: 100px;
    font-size: 12px;
    line-height: 12px;

    @media screen and (max-width: 930px) {
      font-size: 8px;
      line-height: 12px;
    }

    @media screen and (max-width: 450px) {
      padding: 6px 5px;
    }
  }
}

.project-calculator-standard-payment {
  &__subtitle {
    position: relative;

    &--check {
      &::before {
        position: absolute;
        content: '';
        width: 16px;
        height: 16px;
        background-color: $bacground;
        border-radius: 50%;
        top: 3px;
        left: -25px;

        @media screen and (max-width: 450px) {
          width: 10px;
          height: 10px;
          top: 0;
          left: -15px;
        }
      }

      &::after {
        position: absolute;
        content: '';
        width: 10px;
        height: 8px;
        background: url(icons/check.svg) no-repeat center / cover;
        top: 7px;
        left: -22px;

        @media screen and (max-width: 450px) {
          width: 6px;
          height: 5px;
          top: 3px;
          left: -13px;
        }
      }
    }
  }
}

.project-calculator-reduced-payment {
  &__subtitle {
    position: relative;

    &--check {
      &::before {
        position: absolute;
        content: '';
        width: 16px;
        height: 16px;
        background-color: $white;
        border-radius: 50%;
        top: 3px;
        left: -25px;

        @media screen and (max-width: 450px) {
          width: 10px;
          height: 10px;
          top: 0;
          left: -15px;
        }
      }

      &::after {
        position: absolute;
        content: '';
        width: 10px;
        height: 8px;
        background: url(icons/check.svg) no-repeat center / cover;
        top: 7px;
        left: -22px;

        @media screen and (max-width: 450px) {
          width: 6px;
          height: 5px;
          top: 3px;
          left: -13px;
        }
      }
    }
  }
}

.project-calculator-standard-saving {
  &__text {
    background-color: $main;
    color: $white;
  }
}

.project-calculator-reduced-saving {
  &__text {
    background-color: $white;
    color: $text;
  }
}









.investment-calculator {
  padding: 40px 0 30px;

  &__wrapper {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 8px;

    @media screen and (max-width: 744px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__title {
    font-size: 32px;
    line-height: 52px;
    font-weight: 700;
    margin: 0 0 40px;
  }

  &__bar,
  &__standard,
  &__reduced {
    width: 100%;
    max-width: 373px;

    @media screen and (max-width: 744px) {
      max-width: 500px;
    }
  }

  &__bar {
    @media screen and (max-width: 744px) {
      margin-bottom: 32px;
    }
  }
}

.investment-calculator-investments {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 744px) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
}

.investment-calculator-investments-price,
.investment-calculator-investments-initial,
.investment-calculator-investments-time {
  &__lable {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__input {
    padding: 14px 17px;
    font-weight: 700;
    font-size: 14px;
    line-height: 23px;
  }
}

.investment-calculator-investments-initial {
  &__lable {
    position: relative;
  }

  &__percentet {
    position: absolute;
    font-weight: 700;
    font-size: 14px;
    line-height: 23px;
    right: 17px;
    bottom: 14px;
    opacity: 0.5;
  }

  &__text {
    font-size: 10px;
    line-height: 16px;
    margin: 10px 0 0;
  }
}


.investment-calculator-standard,
.investment-calculator-reduced {
  padding: 24px 32px;
  border-radius: 20px;

  @media screen and (max-width: 975px) {
    padding: 24px 10px;
  }
  
  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    margin: 0 0 16px;
  }

  &__percent,
  &__payment,
  &__overpayment {
    margin-bottom: 17px;
  }
}

.investment-calculator-standard {
  background-color: $white;
}

.investment-calculator-reduced {
  background-color: $main;
  color: $white;
}

.investment-calculator-standard-percent,
.investment-calculator-standard-payment,
.investment-calculator-standard-overpayment,
.investment-calculator-reduced-percent,
.investment-calculator-reduced-payment,
.investment-calculator-reduced-overpayment {
  display: flex;
  flex-direction: column;
  
  &__title {
    font-size: 10px;
    line-height: 20px;
  }

  &__value {
    font-weight: 700 !important;
    font-size: 20px;
    line-height: 20px;
    margin: 3px 0;
  }

  &__subtitle {
    font-size: 12px;
    line-height: 20px;
    position: relative;
    margin-left: 23px;
  }
}

.investment-calculator-standard-saving,
.investment-calculator-reduced-saving {
  display: flex;
  align-items: center;
  gap: 18px;

  &__value {
    font-weight: 700 !important;
    font-size: 24px;
    line-height: 30px;
  }

  &__text {
    padding: 5px 10px;
    border-radius: 100px;
    font-size: 12px;
    line-height: 12px;
  }
}

.investment-calculator-standard-saving {
  &__text {
    background-color: $main;
    color: $white;
  }
}

.investment-calculator-reduced-saving {
  &__text {
    background-color: $white;
    color: $text;
  }
}