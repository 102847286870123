.orders {
  ul {
    li {
      list-style-type: none;
    }
  }

  &--partners-header {
    &_item {
      width: 255px !important;
      height: 190px !important;
    }
  }
  .p-accordion { 
    &-header {
      a {
        background-color: $white;
        border: none;
      }
    }

    &-content {
      border: none;
    }
  }

  &-list {
    padding: 32px 40px;

    &--filters {
      margin-top: 30px;
      &_item {
        margin-right: 16px;
        &-label {
          margin-bottom: 8px;
        }
        .p-dropdown {
          //height: 66px !important;
          padding: 21px 16px 21px 16px;
          border: none;
          border-radius: 10px;
          width: 320px !important;
          box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);

          .p-dropdown-label {
            padding: 0;
          }
        }

        .p-calendar {
          box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          .p-inputtext {
            padding: 21px 16px 21px 16px;
            width: 280px;
          }

          button {
            width: 24px;
            height: 24px;
            background-color: white;
            border: none;
            margin-left: -40px;
            margin-top: 20px;
          }
        }
      }
    }

    &--active-status,
    &--archive-status {
      @include standart-button(auto, 40px);
      padding: 8px 24px 8px 24px;
    }

    &--active-status:focus,
    &--archive-status:focus {
      box-shadow: none;
    }

    &--current-order, &--arch-order {
      @include standart-button();
      padding: 16px 40px 16px 40px;
    }

    &--select-type {
      display: flex;
      gap: 16px;
      margin-top: 40px;
      &__button {
        width: 132px;
        height: 40px;
        font-family: 'Regular';
        font-size: 14px;
        line-height: 20px;
        border: 1px solid $main;
        background-color: $white;
        color: $text;

        &:last-child {
          width: auto;
        }
      }
    }
  
    &--active {
      background-color: $main;
      color: $white;
      border: 0;
    }
  
    &--empty_list {
      span {
        padding-top: 150px;
        font-family: 'SemiBold';
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        color: $gray;
        text-align: center;

        @media screen and (max-width: 1280px) {
          font-family: 'Medium';
          font-weight: 500;
        }
      }

      button {
        @include standart-button(235px, 58px);
          margin-top: 40px;
          padding: 16px 40px 16px 40px;
          cursor: pointer;
          font-family: 'Regular';
          font-size: 16px;
      }
    }
  
    &--list_left {
      margin-left: 24px;
      margin-top: 24px;
      width: 466px;
      max-width: 466px;
    }
  
    &--list_right {
      margin-right: 24px;
      margin-top: 24px;
    }
  
    &--list {
      width: 100%;

      &_item {
        .desktop {
          //display: none;
        }
        .mobile {
          display: none;
        }
      }

      .mobile {
        display: none;
      }
  
      &_item__order {
        width: 100%;
      }
  
      &-order_progress {
        width: 120px !important;
        height: 120px !important;
        min-width: 120px;
        min-height: 120px;
        margin-top: 24px;
      }

      span  {
        //font-size: 14px;
        text-align: center;
        text-wrap: nowrap;
      }

      &-order_additional {
        margin-top: 8px !important;
  
        &__icon {
          width: 80px;
          height: 80px;
          border-radius: 100%;
          display: flex;
          align-items: center;
          box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
  
          svg {
            display: block;
            margin: auto;
          }
        }
      }
  
      &-order_payment_status,
      &-order_current_status {
        min-height: 32px;
        height: 32px;
        line-height: 16px;
        font-family: "Regular";
        border-radius: 8px;
        padding: 8px 16px 8px 16px;

        span {
          font-size: 12px;
        }
      }
  
      &-order_current_status {
        border: 1px solid $icon;
        color: $icon;
      }
  
      &-order_payment_status {
        border: 1px solid $main;
        color: $main;
      }
  
      &-order_info {
        margin-left: 56px;
      }
      
      &-order_number {
        margin-bottom: 28px;
      }

      // &-order_name {
      //   font-size: 24px;
      //   font-weight: 500;
      // }
      
      &-order_number,
      &-order_client_name,
      &-order_client_number {
        font-weight: 400px;
        font-size: 14px;
        line-height: 24px;
      }
  
      &-order_name {
        margin-top: 28px;
        font-family: "Medium";
        font-size: 24px;
        line-height: 20px;
      }
  
      &-order_date {
        margin-top: 28px;
        margin-left: 18px;
        font-family: "Regular";
        font-size: 16px;
        line-height: 24px;
        color: $gray;
      }

      &-order_client {
        display: none;
      }
  
      &-order_additional {
        margin-top: 48px !important;
  
        &__icon {
          width: 80px;
          height: 80px;
          border-radius: 100%;
          display: flex;
          align-items: center;
          box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
  
          svg {
            display: block;
            margin: auto;
          }
        }
      }
  
      &-order_price {
        display: block;
        font-family: "SemiBold";
        min-width: 94px;
      }

      &-order_price,
      &-order_payment_status {
        margin-right: 13px;
      }
  
      &-order_button {
        &_btn {
          @include standart-button(109px, 40px);
          margin-top: 112px;
          padding: 16px, 40px, 16px, 40px;
        }
      }
  
      &_item {
        width: 100%;
        height: 248px;
        border-radius: 20px;
        background-color: $white;
      }

      margin-top: 40px;
      &_item {
        margin-top: 20px;

        &-role-pro {
          .orders-list--list-order_info {
            margin-left: 0;

          .orders-list--list {
            &-order_client {
              display: block;
            }

            &-order_number {
              margin-bottom: 0;
            }

            &-order_additional {
              margin-top: -20px !important;
            }

            &-order_additional__icon {
              margin-right: 25px;
            }
          }

            // &-order_client_name, &-order_client_number {
            //   display: none;
            // }
          }
        }

        &-role {
          &-representative,
          &-contractor,
          &-provide,
          &-realtor,
          &-logistic {
            .orders-list--list-order_button {
              button {
                margin-top: 0;
                padding: 8px 16px 8px 16px !important;
              }
            }
          }

          &-customer,
          &-representative,
          &-contractor,
          &-provide,
          &-realtor,
          &-logistic {
            padding: 24px;
            height: auto !important;
            .orders-list--list_item {
              &--title {
                span:nth-child(1) {
                  font-size: 14px;
                  font-family: "Regular";
                }

                span:nth-child(2) {
                  margin-left: 17px;
                  font-size: 14px;
                  font-family: "Regular";
                  color: $gray;
                }
              }

              &--price {
                margin-right: 14px;
                margin-top: 5px;

                & > span {
                  font-family: 'SemiBold';
                  font-size: 16px;
                  line-height: 24px;
                }
              }

              &--button {
                @include standart-button();
                height: 32px;
                width: auto !important;
                border-radius: 8px;
                background-color: $white;
                font-size: 12px;
                border: 1px solid $main;
                color: $main;
                padding: 6px 16px 0 16px;
              }

              &--info {
                margin-top: 28px;

                span {
                  font-size: 16px;
                }

                span:nth-child(1) {
                  font-family: "SemiBold";
                }

                span:nth-child(2) {
                  font-family: "Regular";
                }

                button {
                  @include standart-button(106px, 42px);
                  padding: 8px 24px 10px 24px;
                }                
              }
            } 
          }
        }
      }
    }
  }

  .orders-detail {
    &-order_number {
      padding-left: 0;
      font-weight: 400px;
      font-size: 14px;
      line-height: 24px;
    }

    &-order_name {
      margin-top: 28px;
      font-family: "Medium";
      font-size: 24px;
      line-height: 20px;
    }

    &-order_date {
      margin-top: 28px;
      margin-left: 18px;
      font-family: "Regular";
      font-size: 16px;
      line-height: 24px;
      color: $gray;
    }
  }

  .order-detail {
    margin-top: 94px;
    margin-left: 40px;
    margin-right: 40px;
    &_info, &_inputs {
      border-radius: 20px 20px 20px 20px;
      background-color: $white;
    }

    &_info {
      // max-width: 60%;
      &--preview {
        max-width: 361px;
        margin: 20px;
        img {
          width: 100% !important;
          width: -webkit-max-content;
          width: -moz-max-content;
          width: max-content;
          height: -webkit-max-content;
          height: -moz-max-content;
          height: max-content;
        }
      }

      &--info {
        margin: 20px;
        .orders-detail-order_number,
        .orders-detail-order_namedate {
          padding-left: 0;
        }
      }

      &--status {
        margin: 20px 15px;
        div {
          @include standart-button(auto, 32px);
          padding: 5px;
          border: 1px solid $main;
          // margin-right: 20px;
          background-color: $white;
          color: $main;
          font-size: 12px;
          font-family: "Regular";
          border-radius: 8px;
        }
      }

      &--trim {
        div {
          margin-bottom: 12px;
        }

        span {
          font-size: 16px;
          line-height: 16px;
          font-family: "Regular";
        }
      }

      &--price {
        font-size: 24px;
        font-family: "Bold";
      }

      &--additional {
        border-top: 1px dotted rgba(0, 0, 0, 0.4);        ;
        .p-accordion {
          width: 100%;
          background-color: $white;
        }

        .p-accordion-tab-active {
          .order-detail_info--additional_accordion_img {
            display: none;
          }
        }

        .p-accordion-header	 {
          background-color: $white;

          a {
            svg {
              display: none;
            }
          }
        }

        .p-accordion-content {
          width: 100%;
          padding: 0;
        }

        &_accordion {
          li {
            display: inline-block;
            width: 80px;
            height: 80px;
            margin-left: 16px;
            border-radius: 10px;
            overflow: hidden;
            position: relative;

            img {
              display: block;
              position: absolute;
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .p-accordion-content {
        max-height: 356px;
      }

      &--additional_info {
        max-height: 356px;
        padding: 0;
        ul {
          overflow: auto;
          white-space: nowrap;
          padding-left: 0;
          padding-bottom: 24px;
          li {
            max-width: 255px;
            width: 255px;
            max-height: 180px;
            text-align: center;
            display: inline-block;
            padding: 15px;

            img {
              max-height: 160px;
              width: 100%;
            }

            span {
              margin-top: 16px;
              display: block;
              font-family: "SemiBold";
              font-size: 16px;
            }
          }
        }
        img {
          max-height: 100%;
        }
      }
    }
    &_inputs_block {
      margin-left: 50px;
    }
    &_inputs {
      padding-top: 15px;
      max-width: 384px;
      width: 384px;


      .p-accordion-tab {
        margin-bottom: 15px;
        box-shadow:0px 5px 5px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
      }

      .p-listbox {
        width: 100% !important;
      }

      &--list {
        padding: 0;
        max-width: 320px;

        &_executor {
          &-name {
            font-family: "Medium";
            font-size: 14px;
          }
          &-region {
            font-family: "Regular";
            font-size: 14px;
            color: $text;
          }
          &-select {
            &_btn {
              width: 20px;
              height: 20px;
              background-color: #FAFAFA;
              border: 1px solid $disable;
              border-radius: 10px;
              margin: 10px;
            }
          }
        }

        .p-highlight {
          .order-detail_inputs--list_executor-select_btn {
            border: 6px solid $main;
          }
        }

        ul {
          padding: 8px 0px 0px 0px;
          border-radius: 15px;
          font-size: 14px;
          font-family: "Regular";
          line-height: 24px;

          li {
            cursor: pointer;
            padding: 8px 16px 8px 24px;
            border-top: 1px solid #D9D9D9;
          }
        }

        &_contact {
          li {
            font-size: 14px;
            span {
              font-size: 16px;
              font-family: "SemiBold";
            }
          }

          &__button {
            @include standart-button(113px, 32px);
            font-size: 12px;
            padding: 8px;

            span {
              font-size: 12px !important;
              font-weight: 400 !important;
            }
          }

          li:first-child {
            border: none;
          }
        }

        &_documents {
          span {
            margin-left: 8px;
            max-width: 80%;
            
            a {
              display: block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              text-decoration: none;
              color:$icon;
            }
          }

          &-item {
            position: relative;
          }

          &-eye {
            position: absolute;
            right: 10px;
            top: 20px;
          }
        }
      }

      .p-accordion {
        &-header {
          font-size: 16px;
          border-radius: 15px;
          a {
            padding: 0;
            direction: rtl;
            text-align: left;
            border-radius: 15px;

            span {
              padding: 16px;
            }
          }
        }

        &-content {
          margin-top: -15px;
          border-radius: 15px;
          padding: 0;
        }
      }
    }
  } 

  .order-road-map {
    margin: 70px 40px 0 40px;
    &--item {
      width: 100px;
      height: 100px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      text-align: center;
      position: relative;
      &_completed {
        background-color: $completed;

        svg {
          fill: $gray;
        }
      }

      &_active {
        background-color: $main;
        svg {
          fill: $white;
        }
      }

      &_pending {
        background-color: $disable;

        svg {
          fill: $gray;
        }
      }

      div {
        width: 100%;
      }
      span {
        position: absolute;
        margin-bottom: -140px;
        width: 200px;
        margin-bottom: -140px;
        left: 50%;
        transform: translateX(-50%);
        font-family: "SemiBold";
      }
      &_icon {
        svg {
          display: block;
          margin: auto;
        }
      }
    }

    &--separator {
      width: 40px;
      background-color: #D3D3D3;
      height: 1px;
    }
  }

  &--role-switcher {
    margin-top: 20px;
    // height: 172px;
    // overflow-x: scroll;
    &_item {
      position: relative;
      cursor: pointer;
      width: 225px;
      height: 172px;
      background-color: $white;
      border-radius: 20px;
      margin-right: 8px;
      display: inline-block;

      &-label {
        color: $main;
        margin-left: 16px;
        margin-top: 8px;

        &_additional {
          background-color: $main;
          color: $white;
          padding: 0px 4px 4px 4px;
          border-radius: 5px;
          margin-left: 8px;
        }
      }

      &-img {
        position: absolute;
        right: 0;
        bottom: 0;
      }

      &-img.active {
        path {
          stroke: $main;
        }
      }
    }
  }
}

@media screen and (max-width: $mdBreakpoint) {
  .orders {
    &-list {
      &--list {
        &_item {
          .desktop {
            display: none !important;
          }
          .mobile {
            display: block;
          }
        }
      }
    }
  }
}

.orders-role {
  &__swiper {
    width: 1200px;
    height: 172px;
    margin: 0 auto 40px;
    overflow: hidden;

    @media screen and (max-width: 1279px) {
      width: 930px;
    }

    @media screen and (max-width: 1049px) {
      width: 700px;
    }

    @media screen and (max-width: 774px) {
      width: 450px;
    }

    @media screen and (max-width: 529px) {
      width: 100%;
    }
  }
}

.orders-role-swiper {
  &__slide {
    background-color: $white;
    border-radius: 20px;
    padding: 8px 16px;

    position: relative;
    cursor: pointer;
  }
}

.orders-role-swiper-slide {
  &__text {
    color: $main;

    & > span {
      &:nth-child(2) {
        display: inline-block;
        margin-left: 8px;
        color: $white;
        text-transform: lowercase;
        border-radius: 5px;
        background-color: $main;
        padding: 4px 6px;
        font-size: 10px;
      }
    }
  }

  &__img {
    position: absolute;
    right: 0;
    bottom: 0;

    &--active {
      path {
        stroke: $main;
      }
    }
  }
}

.orders-list {
  &__title {
    font-family: 'Medium';
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
  }

  &__wrapper {
    display: flex;
    align-items: start;
    gap: 40px;
  }

  &__button {
    width: 106px;
    height: 42px;
    outline: none;
    background-color: $main;
    color: $white;
    border-radius: 100px;
    font-family: 'Regular';
    font-size: 14px;
    line-height: 20px;
    border: none;
    display: block;
    margin-left: auto;
    margin-top: -40px;
  }
}

.orders-list-wrapper {
  &__item {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.p-dropdown-clearable {
  .p-dropdown-clear-icon {
    display: none;
    margin-right: 10px;
    margin-top: -8px;
  }
  
  &:hover, &:focus, &:active {
    .p-dropdown-clear-icon {
      display: block;
    }
  }
}