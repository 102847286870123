.categories {
  &__category {
    margin: 0 40px 40px 40px;
    background-color: $white;
    border-radius: 20px;

    @media screen and (max-width: 400px) {
      margin: 20px;
    }
  }
}

.categories-category {
  padding: 40px;

  &__title {
    font-family: 'Medium';
    font-weight: 500;
    margin-top: 40px;
    font-size: 24px;
    font-weight: 500;
  }

  &__list {
    margin: 32px 0;
  }

  &__details {
    margin-top: 80px;
  }
}

.categories-category-list {
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;

  &__item {
    font-family: 'Regular';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    border-radius: 100px;
    padding: 18px 40px;
    display: inline-block;
    color: $text;
    background-color: $white;
    border: 1px solid $main;
    cursor: pointer;

    &--active {
      background-color: $main;
      color: $white;
    }

    &--added {
      padding: 18px 40px;
      font-size: 24px;
      background-color: $main;
      color: $white;
      fill: $white;
    }

    &--added.disabled {
      cursor: none;
      background-color: $white;
      fill: $disable;
    }

    @media screen and (max-width: 530px) {
      width: 100%;
      text-align: center;
    }
  }
}

.categories-category-list-item {
  position: relative;

  &--active {
    &:hover {
      & .categories-category-list-item__close {
       display: block;

       & svg {
          fill: $white;
       }
     }
   }
  }

  &:hover {
     & .categories-category-list-item__close {
      display: block;
    }
  }

  &__close {
    display: none;
    position: absolute;
    width: 12px;
    height: 12px;
    right: 10px;
    bottom: 27px;
    transform: rotate(45deg);

    & svg {
      width: 100%;
      height: 100%;
    }
  }
}

.categories-category-details {
  &__title {
    font-family: 'Medium';
    font-weight: 500;
    margin: 0;
    font-size: 24px;
    font-weight: 500;
  }
  
  &__table {
    margin: 24px 0 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;

    & .p-datatable {
      width: 100%;

      & .p-column-header-content, & .p-datatable-tbody {
        justify-content: center;
        text-align: center;
      }

      & th, & td {
        background-color: $white;
        border: 1px solid $gray;
        border-right: none;

        &:last-child {
          border-right: 1px solid $gray;
        }
      }
    }

    & .p-datatable-wrapper {
      border-radius: 20px;
      overflow: auto;
    }
  }

  &__accordion {
    padding: 24px;

    &>.p-accordion-content {
      height: -webkit-min-content;
      height: -moz-min-content;
      height: min-content;
      max-height: 90vh;
      overflow: auto;
    }
  }

  & .p-accordion {
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    margin: 40px 0;
    width: 100%;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number] {
      -moz-appearance: textfield;
    }

    input[type=number] {
      width: 100%;
      box-sizing: border-box;
      border: none;
    }

    .p-accordion-header-link {
      background-color: #ffffff;
      border: none;
    }

    .p-accordion-content {
      border: none;
    }
  }
}

.categories-category-buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &__sendbutton {
    @include standart-button(162px, 56px);
    font-size: 16px;
    font-weight: 400;
    margin: 0 32px 32px 0;
  }
}

.categories-modal {
  &__body {
    padding: 32px 48px;
    width: 526px;

    @media screen and (max-width: 540px) {
      padding: 16px 16px;
      width: 320px;
    }
  }

  &__title {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin: 0 auto 24px;

    @media screen and (max-width: 540px) {
      font-size: 18px;
    }
  }

  &__text {
    width: 95%;
    max-width: 287px;
    margin: 34px auto 42px;
    line-height: 24px;
    text-align: center;
    color: #000;
    cursor: pointer;

    &--underline {
      text-decoration: underline;
    }
  }

  & .p-dialog-content {
    border-radius: 20px;
    margin: 0;
    padding: 0;
  }

  & .p-dialog-header {
    display: none;
  }

  &__changes {
    width: 95%;
    max-width: 500px;
  }
}

.categories-modal-category {
  &__title {
    width: 100%;
    background-color: #F5F5F5;
    color: #000;
    font-size: 24px;
    line-height: 39px;
    font-weight: 500;
    margin: 24px 0 0;

    @media screen and (max-width: 540px) {
      font-size: 18px;
      line-height: 22px;
    }

    &--name {
      display: block;
      font-size: 16px;
      line-height: 24px;
      margin: 0;

      @media screen and (max-width: 540px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}

.categories-modal-category-list {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 0;
  border-bottom: 1px solid #D9D9D9;

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__name {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    width: 55%;
    max-width: 250px;
    color: #000;

    @media screen and (max-width: 540px) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__text {
    color: $text;

    @media screen and (max-width: 540px) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  &:nth-last-child(1) {
    border: none;
  }
}

.categories-modal-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &__button {
    padding: 16px 40px;
    border: 1px solid $main;
    border-radius: 100px;
    background-color: $white;
    color: $text;
    cursor: pointer;

    @media screen and (max-width: 540px) {
      padding: 10px 20px;
    }

    &--filled {
      background-color: $main;
      color: $white;
    }
  }
}

.categories-modal-form {
  &__input {
    width: 100%;
      height: 66px;
      border: none;
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1) !important;
      border-radius: 10px;
      padding-left: 10px;
      padding-right: 45px;
      color: $text;

      &:hover {
        & ~ .categories-modal-form-lable__close {
          display: block !important;
        }
      }
  
      @media screen and (max-width: 540px) {
        height: 45px;
      }

    &--password {
      padding-right: 70px;
    }
  }
  

  &__lable {
    display: block;
    margin-bottom: 32px;
    line-height: 24px;
    color: $text;

    @media screen and (max-width: 540px) {
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.categories-modal-form-lable {
  position: relative;

  &__close {
    display: none;
    position: absolute;
    width: 14px;
    height: 14px;
    right: 20px;
    bottom: 26px;
    transform: rotate(45deg);
    z-index: 2;

    &:hover {
      display: block;
      cursor: pointer;
    }
  }
}

.category-table {
  display: block;
  width: 100%;
  max-height: 90%;
  overflow: auto;
  border: 1px solid $gray;
  border-radius: 20px 20px 0 0;
  text-align: center;
  font-family: 'Regular';
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  border-spacing: 0;
  position: relative;

  &::-webkit-scrollbar {
      width: 2px;
      height: 5px;
    }
  
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: $gray;
  }

  & > * {
    &::-webkit-scrollbar {
      width: 2px;
      height: 5px;
    }
  
    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: transparent;
    }
  
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: $gray;
    }
  }

  &__head {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
  }
}

.category-table-header {
  font-size: 12px;
  line-height: 16px;
  
  &__item {
    width: 100%;
    min-width: 110px;
    height: 56px;
    border-right: 1px solid $gray;
    overflow: auto;
    background-color: $white;
    cursor: pointer;

    &--invalid {
      background-color: $deleted;
    }

    &:first-child {
      border-top: 0;
      border-left: 0;
    }

    &:nth-child(2) {
      width: 100%;
      min-width: 450px;
    }

    &--number {
      width: 53px;
      min-width: 53px;

      &:hover {
        cursor: not-allowed;
        background-color: $white !important;
      }
    }

    &--exercise {
      min-width: 188px;
    }

    &:hover {
      background-color: $hover;
    }
  }

  &__icon {
    display: inline-block;

    &:nth-child(2) {
      margin-left: 16px;
    }
  }

  &__input {
    width: 100%;
    height: 100%;
    border: none;
  }

  &__text {
    font-family: 'SemiBold';
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    resize: none;
    border: none;
    width: 110px;
    text-align: center;
    background-color: transparent;
    display: inline-table;
    cursor: pointer;
    
    &:focus {
      outline: none;  
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
}

.category-table-row {
  position: relative;

  &__item {
    padding: 18px 8px;
    border-right: 1px solid $gray;
    border-top: 1px solid $gray;
    width: 100%;
    min-width: 200px;
    height: 70px;
    overflow-y: hidden;
    cursor: pointer;

    &--invalid {
      background-color: $deleted;
    }

    &--new {
      input { 
        color: #F4970C;
      }
    }

    &:first-child {
      min-width: auto;
    }

    &--number {
      &:hover {
        background-color: $deleted !important;
      }
    }

    &--name {
      text-align: left;
    }
    
    &--fix {
      border: 2px solid $warning;
      position: relative;

      .old {
        position: absolute;
        font-size: 10px;
        color: $gray;
        top: 5%;
        left: 50%;
        transform: translateX(-50%);
        text-decoration: line-through;
        -webkit-text-decoration-color: $error;
                text-decoration-color: $error;
      }
    }

    &--added {
      position: relative;
      width: 77px;
      min-width: auto !important;
      border: none !important;

      span {
        position: -webkit-sticky;
        position: sticky;
        display: block;
        top: 35%;
        bottom: 35%;
        left: 0;
        color: $text !important;
      }

      &__img {
        width: 24px;
        height: 24px;
        margin-top: 4px;
        transform: rotate(45deg);

        & path {
          fill: $icon;
        }
      }

      &:hover {
        background-color: $white !important;
      }
    }

    &:hover {
      background-color: $hover;
    }
  }

  &__input {
    background-color: transparent;
    border: none;
    font-size: 14px;
    line-height: 24px;
    color: $text;
    text-align: center;
    cursor: pointer;
  }

  &--del {
    .category-table-row {
      &__item {
        border: 2px solid $error;
      }
    }
  }

  &--add {
    .category-table-row {
      &__item {
        border: 2px solid $adding;
      }
    }
  }
}

.category-added-material {
  padding: 18px;
  border: 1px solid $gray;
  border-top: none;
  border-radius: 0 0 20px 20px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  &__img {
    width: 24px;
    height: 24px;
    margin-top: 4px;
    transform: rotate(45deg);

    & path {
      fill: $icon;
    }
  }
}

.disabled {
  cursor: not-allowed;
  border-color: $disable;
  color: $disable;
  border: 1px solid $disable;
  background-color: transparent !important;

  &:hover {
    background-color: transparent !important;
  }
}

.hover {
  background-color: $hover;
}

.deleted {
  background-color: $deleted;
}

.categories-modal-changes {
  &__wrapper {
    padding: 32px 48px;
    position: relative;

    @media screen and (max-width: 465px) {
      padding: 32px 16px;
    }
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  &__title {
    font-family: 'Medium';
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    margin: 0 auto 24px;

    &--mobile {
      margin: 0;

      @media screen and (max-width: 465px) {
        display: none;
      }
    }
  }

  &__text {
    font-family: 'Regular';
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 32px;
  }

  & .p-dialog-content {
    border-radius: 20px;
    margin: 0;
    padding: 0;
  }

  & .p-dialog-header {
    display: none;
  }
}

.categories-modal-changes-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    border-radius: 100px;
    width: 165px;
    height: 58px;
    border: 1px solid $main;
    font-family: 'Regular';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;

    @media screen and (max-width: 465px) {
      width: 110px;
      height: 46px;
    }

    &--active {
      color: $white;
      background-color: $main;
    }
  }
}

// костыли потом поправить
.categories-category-details__table {
  .p-accordion-header-link {
    flex-direction: row-reverse;
  }

  .p-accordion-header-text {
    font-family: 'Regular';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}

// .category-table-header__item {
//   &:not(:nth-child(4)) {
//     & > .category-table-header__text {
//       height: max-content;
//     }
//   }
// }