.offer {
  margin: 70px 40px 0 40px;

  &--header {
    &--title {
      span {
        font-size: 32px;
        font-family: "Medium";
        font-weight: 500;
      }
    }
    button {
      @include standart-button(142px, 56px);
      border: 1px solid $main;
      background-color: $white;
      color: $text;
      cursor: pointer;
      font-size: 14px;
      font-family: "Regular";
    }
  }

  &--fields {
    margin-top:28px;
    &-item {
      &_label {
        font-weight: 600 !important;
      }

      button {
        @include standart-button(106px, 40px);
        cursor: pointer;
        font-size: 14px;
        font-family: "Regular";
      }
    }
  }

  &--table {
    margin-top: 60px;
    .p-datatable-wrapper {
      border-radius: 20px;
    }

    .p-datatable {
      width: 100%;
      td {
        text-align: center;
        border: {
          left: 1px solid $gray;
          bottom: 1px solid $gray;
        }
      }

      td:last-child {
        border: {
          right: 1px solid $gray;
        }
      }

      .p-datatable-header {
        background-color: $bacground;
      }

      th {
        background-color: $white;
        border: 1px solid $gray;
        border-right: none;
      }

      th:last-child {
        border-right: 1px solid $gray;
      }

      .p-column-title {
        font-size: 14px;
        font-family: "Regular";
      }

      .p-datatable-footer {
        background-color: $white;
        border-radius: 0 0 20px 20px;
      }
    }

    &-documents {
      cursor: pointer;
    }
  }

  & .p-datatable .p-datatable-thead > tr > th,
    .p-datatable .p-datatable-tbody > tr > td {
    padding: 1rem 1rem;
  }
}