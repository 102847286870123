.village {
  padding: 20px;
  margin: 0;

  &__header {
    width: 100%;
    max-width: 1840px;
    margin: 0 auto;
  }

  &__wrapper {
    width: 100%;
    max-width: 1720px;
    margin: 0 auto;
  }

  &__back {
    margin-bottom: 20px;
  }

  &__swiper {
    width: 100%;
    height: 150px;
    margin: 0 auto 40px;
    overflow: hidden;

    @media screen and (max-width: 1600px){
      width: 1200px;
      height: 172px;
    }

    @media screen and (max-width: 1279px) {
      width: 930px;
    }

    @media screen and (max-width: 1049px) {
      width: 700px;
    }

    @media screen and (max-width: 743px) {
      width: 450px;
    }

    @media screen and (max-width: 529px) {
      width: 100%;
    }
  }
}

.village-back {
  display: flex;
  align-items: center;
  gap: 8px;
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;

  &__icon {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 2px;
  
      & svg {
        width: 15px;
      }
    }
  
    &__text {
      font-size: 14px;
      margin: 4px 0;
    }
}

.village-swiper {
  &__slide {
    background-color: $white;
    border-radius: 20px;
    padding: 8px 16px;

    position: relative;
    cursor: pointer;
  }
}

.village-swiper-slide {
  &__text {
    display: flex;
    align-items: flex-start;
    font-family: 'SemiBold';
    font-weight: 600;
    gap: 8px;
    color: $main;

    & span {
      display: block;

      &:first-child {
        max-width: 141px;
      }

      &:nth-child(2) {
        font-family: 'Regular';
        font-weight: 400;
        font-size: 10px;
        padding: 10px;
        line-height: 10px;
        padding: 4px;
        text-transform: lowercase;
        border-radius: 5px;
        background-color: $main;
        color: $white;
      }
    }
  }

  &__img {
    position: absolute;
    right: 0;
    bottom: 0;

    &--active {
      path {
        stroke: $main;
      }
    }
  }
}

.village-main {
  padding: 80px 0 40px;
  display: grid;
  align-items: start;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;

  @media screen and (max-width: 744px) {
    gap: 8px;
  }
  
  @media screen and (max-width: 740px) {
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 600px) {
    gap: 16px;
  }
}

.village-gallery { 
  position: -webkit-sticky; 
  position: sticky;
  top: 5px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (max-width: 744px) {
    gap: 8px;
  }

  @media screen and (max-width: 740px) {
    position: static;
  }

  &__wrapper {
    position: relative;

    .swiper-slide {
      width: 100% !important;
    }
  }

  &__lable {
    position: absolute;
    top: 16px;
    left: 16px;
    display: block;
    background-color: $main;
    border-radius: 8px;
    font-family: 'Regular';
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding: 8px 16px;
    color: $white;
  }

  &__img {
    width: 100%;
    max-width: 1022px;
    height: 100%;
    max-height: 700px;
    border-radius: 20px;
  }

  &__buttons {
    position: absolute;
    z-index: 2;
    left: 8px;
    bottom: 8px;
    display: flex;

    @media screen and (max-width: 744px) {
      position: static;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content;
      margin: 8px auto 0;
    }

    @media screen and (max-width: 600px) {
      margin-top: 16px;
    }
  }

  &__description {
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }
}

.village-gallery-buttons {
  display: flex;
  align-items: center;
  gap: 16px;

  &__item {
    width: 120px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    border: 1px solid $main;
    background-color: $white;
    cursor: pointer;

    &--active {
      color: $white;
      background-color: $main;
    }
  }
}

.village-description { 
  padding: 24px 32px 18px;
  background-color: $white;
  border-radius: 20px;

  @media screen and (max-width: 600px) {
    display: none;
  }

  &__text {
    margin: 0;
    height: 100%;
  }
}

.village-options { 
  position: -webkit-sticky; 
  position: sticky;
  top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 740px) {
    position: static;
    width: 100%;
  }

  &__wrapper {
    padding: 24px 32px;
    background-color: $white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 24px;
  }

  &__subtitle {
    font-family: "SemiBold";
    font-weight: 600;
    font-size: 24px;
    line-height: 39px;
    margin: 0 0 10px;

    @media screen and (max-width: 500px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 10px;
  }

  &__button {
    width: 100%;
    background-color: $main;
    color: $white;
    padding: 16px;
    text-align: center;
    border-radius: 100px;
    margin-top: 42px;
    cursor: pointer;

    @media screen and (max-width: 600px) {
      margin-top: 16px;
    }
  }
  
  &__summary {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: auto;
  }
}

.village-options-header {
  &__title {
    display: flex;
    flex-direction: column;
    margin: 0;
    font-size: 32px;
    line-height: 53px;
    font-family: "Medium";
    font-weight: 500;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    
    &--subtitle {
      font-family: 'Regular';
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;

    }

    @media screen and (max-width: 600px) {
      line-height: 40px;
    }
  }

  &__amount {
    font-family: "Medium";
    font-weight: 500;
    font-size: 24px;
    line-height: 39px;
    display: block;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }
}

.village-options-list {
  &__item {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.village-options-summary {
  &__title {
    margin: 0;
    font-family: "SemiBold";
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  &__item {
    display: flex;
    justify-content: space-between;
  }
}

.village-options-summary-item {
  &__label {
    color: $gray;
  }

  &__value {
    text-align: right;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    min-width: 70px;
  }
}







.village-program {
  &__title {
    font-size: 30px;
    line-height: 30px;
    font-family: "Medium";
    font-weight: 500;
    margin-bottom: 16px;

    @media screen and (max-width: 740px) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__text {
    font-size: 24px;
    line-height: 24px;
    font-family: "Medium";
    font-weight: 500;

    @media screen and (max-width: 1200px) {
      font-size: 18px;
    }

    @media screen and (max-width: 740px) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__cards {
    margin: 24px 0 40px;

    @media screen and (max-width: 740px) {
      margin: 16px 0 24px;
    }
  }
}

.village-program-cards {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 35px;

  @media screen and (max-width: 1280px) {
    gap: 24px;
  }

  @media screen and (max-width: 740px) {
      gap: 5px;
  }

  &__item {
    width: 100%;
    height: 150px;
    border-radius: 20px;
    background-color: $white;

    @media screen and (max-width: 740px) {
     height: 90px; 
    }
  }
}

.village-program-card {
  padding: 16px;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  @media screen and (max-width: 740px) {
    padding: 5px;
  }

  &__title {
    font-family: "Medium";
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 16px;

    @media screen and (max-width: 1280px) {
      line-height: 24px;
    }

    @media screen and (max-width: 743px) {
      margin-bottom: 0;
    }

    @media screen and (max-width: 740px) {
      font-size: 12px;
      line-height: 24px;
      margin-bottom: 0;
    }
  }

  &__text {
    font-family: 'Regular';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    max-width: 180px;

    @media screen and (max-width: 1280px) {
      line-height: 20px;
    }

    @media screen and (max-width: 1170px) {
      max-width: 140px;
    }

    @media screen and (max-width: 970px) {
      font-size: 12px;
      max-width: 108px;
    }

    @media screen and (max-width: 740px) {
      font-size: 10px;
      line-height: 12px;
    }
  }

  &__img {
    position: absolute;
    right: 0;
    bottom: 0;
    
    & svg {
      width: 100%;
      height: 100%;

      & path {
        stroke: $disable;

        @media screen and (max-width: 740px) {
          stroke: $main;
        }
      }
    }

    @media screen and (max-width: 740px) {
      width: 50px;
      height: 40px;
    }
  }

  &:hover {
    .village-program-card {
      &__img {
        & svg {
          & path {
            stroke: $main;
          }
        }
      }
    }
  }

  &:nth-child(1) {
    & .village-program-card {
      &__img {
        @media screen and (max-width: 900px) {
          right: -35px;
        }

        @media screen and (max-width: 740px) {
          right: 0;
        }
      }
    }
  }

  &:nth-child(2) {
    & .village-program-card {
      &__img {
        @media screen and (max-width: 900px) {
          right: -50px;
        }

        @media screen and (max-width: 740px) {
          right: 0;
        }
      }
    }
  }

  &:nth-child(3) {
    & .village-program-card {
      &__img {
        @media screen and (max-width: 900px) {
          right: -10px;
        }

        @media screen and (max-width: 740px) {
          right: 0;
        }
      }
    }
  }
}

.village-calculator {
  &__title {
    font-family: 'Medium';
    font-weight: 500;
    font-size: 32px;
    line-height: 53px;

    @media screen and (max-width: 740px) {
      font-size: 18px;
      line-height: 24px;
      margin: 0 auto 16px;
      max-width: 550px;
    }
  }

  &__triggers {
    margin: 24px 0;

    @media screen and (max-width: 744px) {
      margin: 16px 0;
    }

    @media screen and (max-width: 740px) {
      max-width: 550px;
      margin: 16px auto;
    }
  }

  &__blocks {
    margin-bottom: 16px;
  }

  &__annotation {
    font-family: 'Regular';
    font-size: 14px;
    line-height: 24px;
    color: $gray;

    @media screen and (max-width: 740px) {
      margin: 0 auto;
      max-width: 550px;
    }

    @media screen and (max-width: 400px) {
      font-size: 10px;
      line-height: 12px;
    }
  }
}

.village-calculator-triggers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 35px;

  @media screen and (max-width: 1280px) {
    gap: 25px;
  }

  @media screen and (max-width: 744px) {
    gap: 15px;
  }

  &__item {
    width: 100%;
    padding: 16px;
    border-radius: 20px;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Medium';
    font-weight: 500;
    font-size: 24px;
    line-height: 22px;
    color: $gray;
    cursor: pointer;

    @media screen and (max-width: 744px) {
      font-size: 14px;
      padding: 11px;
    }

    &--active {
      border: 1px solid $main;
      color: $main;
    }
  }
}

.village-calculator-blocks {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 35px;

  @media screen and (max-width: 1280px) {
    gap: 25px;
  }

  @media screen and (max-width: 744px) {
    gap: 15px;
  }

  @media screen and (max-width: 740px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: $white;
    border-radius: 20px;
    width: 100%;
    max-width: 550px;
    padding: 24px;
  }

  &__result {
    width: 107%;
    
    @media screen and (max-width: 900px) {
      width: 112%;
    }
    
    @media screen and (max-width: 744px) {
      width: 100%;
      max-width: 550px;
    }
  }
}

.village-calculator-blocks-input {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__lable {
    font-family: 'Medium';
    font-size: 10px;
    line-height: 16px;
  }

  &__item {
    width: 100%;
    padding: 16px;
    font-family: 'SemiBold';
    font-size: 16px;
    line-height: 24px;
    color: $text;

    &::-webkit-input-placeholder {
      color: $text;
      opacity: 1;
    }

    &::-moz-placeholder {
      color: $text;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: $text;
      opacity: 1;
    }

    &::-ms-input-placeholder {
      color: $text;
      opacity: 1;
    }

    &::placeholder {
      color: $text;
      opacity: 1;
    }
  }

  &--disabled {
    & .village-calculator-blocks-input {
      &__lable {
        color: $disable;
      }

      &__item {
        &::-webkit-input-placeholder {
          color: $disable;
        }
        &::-moz-placeholder {
          color: $disable;
        }
        &:-ms-input-placeholder {
          color: $disable;
        }
        &::-ms-input-placeholder {
          color: $disable;
        }
        &::placeholder {
          color: $disable;
        }
      }
    }
  }
}

.village-calculator-result {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }

    @media screen and (max-width: 740px) {
      display: none;
    }

    &--mobile {
      display: none;

      @media screen and (max-width: 740px) {
        display: flex;
        max-width: 550px;
        margin: 0 auto;
      }
    }
  }

  &__button {
    padding: 16px 13px;
    width: 100%;
    min-width: -webkit-min-content;
    min-width: -moz-min-content;
    min-width: min-content;
    border-radius: 100px;
    border: 1px solid $main;
    background-color: $white;
    color: $text;
    font-family: 'Regular';
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    cursor: pointer;

    @media screen and (max-width: 744px) {
      font-size: 14px;
      line-height: 20px;
      padding: 11px;
    }

    &--active {
      color: $white;
      background-color: $main;
    }
  }

  &__card {
    height: -webkit-fill-available;
    padding: 24px 32px;
    border-radius: 20px;
    background-color: $main;
    color: $white;

    &--disabled {
      background-color: $gray;
    }
  } 
}

.village-calculator-result-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  &__info {
    display: flex;
    flex-direction: column;
  }

  &__lable {
    font-family: 'Regular';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    @media screen and (max-width: 744px) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__numbers {
    font-family: 'Medium';
    font-weight: 500;
    font-size: 30px;
    line-height: 50px;

    @media screen and (max-width: 744px) {
      font-size: 24px;
      line-height: 24px;
    }
  }
}