.input {
  color: $text;

  &-container {
    position: relative;
  }

  &-validation-icon {
    position: absolute;
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    cursor: pointer;
    user-select: none;
  }

  &-password {
    padding-right: 70px;
    &-icon {
      display: flex;
      gap: 10px;
      align-items: center;
      cursor: pointer;
      user-select: none;

      position: absolute;
      right: 10px;
      top: 0;
      bottom: 0;
      margin: auto 0;
    }
  }

  &-tooltip {
    .p-tooltip-text {
      color: $text;
      background: white;
      border-radius: 10px;
      max-width: 600px;
    }

    .p-tooltip-arrow {
      border-bottom-color: white;
    }
  }

  &[type='password'] {
    font: small-caption;
    letter-spacing: 4px;
  }

  &-error {
    &-info {
      border: {
        bottom: 2px solid $warning;
        top: none;
        right: none;
        left: none;
      }
    }

    &-auth {
      border: 2px solid $warning;
    }
  }

  &-valid {
    &-auth {
      &:enabled {
        border: 2px solid $main;
      }
    }
  }
}
