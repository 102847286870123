.terms {
  padding: 32px 40px;
  font-family: 'Regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: $text;

  @media screen and (max-width: 550px) {
    padding: 20px 10px;
    font-size: 12px;
    line-height: 16px;
  }

  &__header {
    width: 100%;
    max-width: 1840px;
    margin: 0 auto;
  }

  &__wrapper {
    margin: 0 20px;
  }
}

.terms-use,
.terms-personal,
.terms-advertising,
.terms-partnership {
  padding: 24px 32px;
  background-color: $white;
  border-radius: 20px;

  @media screen and (max-width: 550px) {
    padding: 20px 10px;
  }

  &__title {
    text-align: center;
    margin: 0 auto 48px;
    font-family: 'Medium';
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;

    @media screen and (max-width: 550px) {
      font-size: 18px;
      line-height: 18px;
      margin-bottom: 30px;
    }
  }

  &__desc {
    text-indent: 15px;
    margin-bottom: 8px;
  }

  &__info {
    margin-top: 40px;

    @media screen and (max-width: 550px) {
      margin-top: 20px;
    }
  }
}

.terms-use-info {
  &__title {
    font-family: 'SemiBold';
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 8px;
    text-decoration: underline;
  }
}

.terms-use-info-list,
.terms-partnership-list {
  margin-left: 20px;
  counter-reset: num;

  &--2 {
    counter-reset: num2;
    margin-left: 5px;
  }

  &--3 {
    counter-reset: num3;
    margin-left: 2px;
  }

  &--type-a {
    & > .terms-partnership-list__item {
      list-style-type: lower-alpha;
    }
  }
  
  &__item {
    list-style-type: auto;
    counter-increment: num;

    &--2 {
      text-decoration: none !important;
      font-family: 'Regular';
      font-weight: 400;
      list-style-type: none;
      counter-increment: num2;
      
      &::before {
        content: counter(num) '.' counter(num2);
        margin-right: 10px;
        margin-left: -20px;
      }
    }

    &--3 {
      text-decoration: none !important;
      font-family: 'Regular';
      font-weight: 400;
      list-style-type: none;
      counter-increment: num3;
      
      &::before {
        content: counter(num) '.' counter(num2)  '.' counter(num3);
        margin-right: 10px;
        margin-left: -20px;
      }
    }

    & > .bold {
      font-family: 'SemiBold';
      font-weight: 600;
    }

    &--title {
      margin-top: 40px;

      @media screen and (max-width: 550px) {
        margin-top: 20px;
      }

      & > .underline {
        font-family: 'SemiBold';
        font-weight: 600;
        line-height: 24px;
        text-decoration: underline;
      }
    }
  }

  &--marker {
    margin: 0 0 0 20px;

    & > .terms-partnership-list__item {
      list-style-type: disc;
    }
  }
}