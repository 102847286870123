.profile {
  &-mobile {
    display: none !important;
  }

  &-dialog {
    &.p-dialog {
      border-radius: 20px;
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    }
    .p-dialog-content {
      padding: 0;
      border-radius: 20px;
    }
  }

  &-header {
    &-pencil {
      cursor: pointer;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
    }
  }

  &-info {
    max-width: 640px;
    min-width: -webkit-min-content;
    min-width: -moz-min-content;
    min-width: min-content;
    &-validation-icon {
      position: absolute;
      top: 17%;
      left: 10px;
      cursor: pointer;
    }

    &-select {
      margin-top: 5px;
      border-radius: 10px;

      .p-highlight {
        background: rgba(137, 165, 30, 0.2);
      }

      .p-multiselect-close {
        display: none;
      }

      .p-multiselect-header {
        border-radius: 10px;
        background: none;
      }

      .p-multiselect-filter-container {
        input {
          box-shadow: none;
          &:hover {
            border: 1px solid $main;
          }
          &:focus {
            border: 1px solid $main;
          }
        }
      }

      .p-multiselect-items {
        .p-highlight {
          background: none;
        }
      }

      .p-focus {
        background: none;
      }

      .p-checkbox {
        .p-highlight {
          background: $main;
        }

        .p-checkbox-box {
          border-color: $main;
          background: none;
        }
      }
    }

    &-chip {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 15px;
      background: white;
      border: $text solid 1px;
      border-radius: 10px;
      padding: {
        top: 10px;
        bottom: 10px;
        right: 10px;
        left: 10px;
      }

      svg {
        fill: $icon;
        cursor: pointer;
        width: 25px;
        height: 25px;

        &:hover {
          fill: $main;
          transition: fill linear 100ms;
        }
      }
    }
  }

  &-form {
    &-label {
      &-email {
        width: 100%;
      }
    }
    &-input {
      &.p-inputtext {
        color: $text;
        font-size: 16px;
        &:enabled {
          box-shadow: none;
        }
        &:disabled {
          opacity: 1;
        }
      }
      font-size: 16px;
      &:disabled {
        color: $text;
      }
      &:focus {
        outline: none;
      }
      &-info {
        &.p-inputtext {
          color: $text;
          font-size: 16px;
          &:enabled {
            box-shadow: none;
          }
          &:disabled {
            opacity: 1;
          }
        }
        width: 100%;
        background: none;
        outline: none;
        font-size: 16px;
        border: {
          bottom: 2px solid $main;
          top: none;
          right: none;
          left: none;
        }
        padding: {
          top: 0;
          bottom: 5px;
          left: 5px;
          right: 5px;
        }

        &:disabled {
          background: none;
          outline: none;
          border: none;
          color: $text;
          padding: 0;
        }
      }
    }
  }

  &-valid-auth {
    border: 2px solid $main;
  }

  &-button {
    &-save {
      height: 56px;
      width: 165px;
    }
  }

  &-photo {
    border-radius: 20px;
    -o-object-fit: contain;
       object-fit: contain;
  }

  &-tabs {
    .p-tabview-panels {
      background: none;
      padding-left: 0;
    }

    .p-tabview-nav {
      background: none;
    }

    .p-tabview-nav-link {
      background: none;
      
      &:first-child {
        padding-left: 0;
        padding-right: 24px;
      }
    }

    &-header {
      &.p-tabview-selected {
        a {
          color: $main;
          border-color: $main;
        }
      }

      &__invalid {
        .p-tabview-nav-link {
          color: $warning;
          border-color: $warning;
        }
      }
    }
  }

  button {
    padding: 17px 40px;
  }
}

// костыли потом поправить
.profile-slider {
  padding: 40px 0;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  max-width: 1840px;
  margin: 0 auto;

  @media screen and (max-width: 1919px) {
    max-width: 1600px;
  }

  @media screen and (max-width: 1678px) {
    max-width: 1200px;
  }

  @media screen and (max-width: 1279px) {
    max-width: 930px;
  }

  @media screen and (max-width: 1049px) {
    max-width: 700px;
  }

  @media screen and (max-width: 780px) {
    display: none;
  }
  
  &__item {
    position: relative;
    width: 100%;
    height: 150px;
    background-color: $white;
    border-radius: 20px;
    cursor: pointer;
  }

  
}

.profile-slider-item {
  padding: 10px 13px;
  &__img {
    position: absolute;
    width: 123px;
    height: 116px;
    right: 20px;
    bottom: 0;
    
    path {
      stroke: $bacground;
    }

    &--active {
      path {
        stroke: $main;
      }
    }

    &--referal {
      path {
        &:not(:nth-last-child(2)) {
          fill: $main;
        }
      }
    }
  }

  &__text {
    font-family: 'SemiBold';
    font-weight: 600;
    font-size: 20px;
    line-height: 18px;
    color: $main;
  }
}

@media screen and (max-width: $mdBreakpoint) {
  .profile {
    &-desktop {
      display: none !important;
    }

    &-mobile {
      display: block !important;
    }
  }
}

.profile-button {
  &__edit {
    margin-top: 24px;
    border-radius: 100px;
    padding: 17px 83px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    font-family: 'Regular';
    font-size: 16px;
    line-height: 24px;
    background-color: $main;
    color: $white;
    cursor: pointer;
  }
}

.p-multiselect-panel .p-multiselect-header {
  padding: 0.5rem 1rem;
  .p-multiselect-filter-container .p-inputtext {
    padding-right: 1.5rem;
  }
}

.p-inputtext {
  padding: 0.5rem 0.5rem;
}

.p-multiselect-filter-icon {
  margin-top: -0.5rem;
}

.p-multiselect .p-multiselect-label {
  padding: 0.5rem 0.5rem;
}

.p-multiselect-panel .p-multiselect-items {
  padding: 0.5rem 0;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  padding: 0.5rem 1rem;

  .p-checkbox {
    margin-right: 0.5rem;
  }
}

.profile-info-select .p-checkbox .p-checkbox-box {
  &[data-p-highlight="true"] {
    background-color: $main;
  }
}