.users {
  margin: {
    top: 32px;
    right: 40px;
    left: 40px;
  }

  &-list {
    &--header {
      &-title {
        span {
          font-size: 32px;
          line-height: 40px;
          font-family: "Medium";
          font-weight: 500;
        }
      }
      
      &-request {
        button {
          @include standart-button(117px, 40px);
          background-color: $white;
          border: 1px solid $main;
          color: $text;
          cursor: pointer;
        }
      }
    }

    &--filters {
      margin-top: 44px;
      &-filter {
        margin-right: 16px;
        .p-dropdown {
          margin-top: 8px;
          padding: 21px 16px 21px 16px;
          border: none;
          border-radius: 10px;
          width: 256px !important;
          box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);

          .p-dropdown-label {
            padding: 0;
          }
        }
      }
    }

    &--item {
      background-color: $white;
      border-radius: 20px;

      &_card { 
        margin-top: 26px;
        padding: 24px;
        position: relative;

        &-name {
          margin-top: 28px;
          font-size: 28px;
          line-height: 38.81px;
          font-family: "Medium";
        }

        &-additional {
          margin-top: 14px;
          position: absolute;
          right: 20px;
          bottom: 20px;
          button {
            @include standart-button(117px, 40px);
            cursor: pointer;
            font-size: 14px;
            font-family: "Regular";
          }
        }
      }
    }
  }

  &--more-btn {
    margin-top: 30px;
    button {
      @include standart-button(165px, 40px);
      cursor: pointer;
    }
  }
}